import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//全局过滤器文件
import * as custom  from '@/filter/filter.js'
Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key])
})
Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.prototype.$imgUrl='/api/sysFileInfo/preview?id=';
// Vue.prototype.$imgUrl='https://www.shxhbh.com/api/sysFileInfo/preview?id=';

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
	  this.$store.dispatch('load')
  }
}).$mount('#app')
