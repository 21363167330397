<template>
	<div class="wrap">
		<div class="phone_banner">
			<smallNavbar @current="current()"></smallNavbar>
		</div>
		
		<div class="phoneContent" v-if="$route.query.id!=4">
			<div class="tabBoxPhone"  v-if="$route.query.id==3">
				<div class="itembox" :class="{active:tabcurrent==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
					<span>{{item}}</span>
					<div class="line"></div>
				</div>
			</div>
			<div class="title">{{hospital.title}}</div>
			<div class="time">发布时间：{{hospital.createTime}} </div>
			<img :src="$imgUrl+hospital.imagePath1" alt="" style="width: 100%;height: 300px;" v-if="hospital.imagePath1&&$route.query.id!=6">
			<div style="width: 100%;" v-if="$route.query.id==6">
				<iframe :src="hospital.linkUrl" style="height: 500px;width:100%" scrolling="auto" border="0" frameborder="no" framespacing="0" allowfullscreen="true" ></iframe>
			</div>
			<div class="content" v-html="hospital.memo"></div>
		</div>
		<!-- 表格 -->
		<div class="phoneContent" v-else>
			<table>
				<tr>
					<td>职位名称</td>
					<td>{{hospital.title}}</td>
				</tr>
				<tr>
					<td>科室</td>
					<td>{{hospital.dictCode}}</td>
				</tr>
				<tr>
					<td>招聘人数</td>
					<td>{{hospital.imagePath1}}</td>
				</tr>
				<tr>
					<td>内容要求</td>
					<td><div v-html="hospital.memo"></div></td>
				</tr>
				<tr>
					<td>备注</td>
					<td></td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
	import smallNavbar from "./components/smallNavbar.vue";
	import { GetTalentRecruitment } from "@/api/api";
	export default{
		components:{smallNavbar},
		data(){
			return{
				tabcurrent:0,
				tabguid:'',
				list:["就诊流程","来院引导"],
				hospital:{},
			}
		},
		mounted() {
			this.GetData()
			
		},
		methods:{
			GetData(){
				this.hospital=[]
				GetTalentRecruitment({guid: this.tabguid||this.$route.query.guid,status: 1}).then(res => {
				    this.hospital=res.data.data.rows[0]
					console.log(this.hospital)
				})
			},
			changeActive(row,index){
				this.tabcurrent=index;
				if(index==0){
					this.tabguid='a6a9a559-5d84-4ee6-9524-2484525ccfc8'
				}else if(index==1){
					this.tabguid='c5c4008e-c5cf-4c43-9cc8-ccde9ce98fa8'
				}
				this.GetData()
			},
			current(e){
				this.tabcurrent=0
				this.tabguid=""
				console.log(e,'eee')
				if(this.$route.query.id){
					this.GetData()
				}
			}
		}
	}
</script>

<style lang="scss">
	.wrap{
		overflow: hidden;
	}
	// 右边主要内容
	.phoneContent{
		padding: 20px;
		padding-top:70px;
		box-sizing: border-box;
		.title{
			font-size: 16px;
			font-weight: bold;
			text-align: center;
		}
		.time{
			font-size: 14px;
			color:#666666;
			text-align: center;
			margin: 15px 0 30px;
			span{
				margin-left: 20px;
			}
		}
	}
	::v-deep.img{
		width: 100%!important;
		height:auto;
	}
	.ql-align-center{
		text-align: center;
	}
	.ql-align-justify {
	  text-align: justify;
	}
	.ql-align-right {
	  text-align: right;
	}
	.ql-size-small {
	  font-size: 14px;
	}
	.ql-size-large{
		font-size: 18px;
	}
	.ql-size-huge{
		font-size: 32px;
	}
	.phone_banner{
		width:100%;
		position: fixed;
		z-index:999;
	}
</style>
<style lang="scss" scoped>
	table{
		margin-top: 30px;
		border:1px solid #E4E4E4;
		border-collapse: collapse;
		font-size: 16px;
		width: 100%;
		tr{
			td{
				border:1px solid #E4E4E4;
				padding:10px 10px;
				text-align: justify;
			}
			td:first-child{
				width: 70px;
				background: #F4F4F4;
				color:#999;
			}
		}
	}
	::v-deep.content img{
		max-width: 350px!important;
	}
	
</style>
