<template>
	<div class="wrapBox">
		<navheader current="3"></navheader>
		<div class="contentbox">
			<bannerswipt class="banner"></bannerswipt>
			<div class="leftbox">
				<div class="redbox">
					<div class="china">门诊导航</div>
					<!-- <div class="english">Department</div> -->
				</div>
				<div class="borderbox">
					<div class="listBox">
						<div :class="{active:current==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
							<span>{{item}}</span>
							<div class="line"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="crumbs">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item>门诊导航</el-breadcrumb-item>
					  <el-breadcrumb-item :to="{ path: '/hospitalGuide' }">来院指引</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="maincontent">
					<img :src="$imgUrl+info.imagePath1" style="width:100%">
					<div v-html="info.memo" class="des"></div>
				</div>
			</div>
		</div>
		<footercommon></footercommon>
	</div>
</template>

<script>
	import navheader from '../components/navheader.vue'
	import footercommon from '../components/footercommon.vue'
	import bannerswipt from "../components/bannerswipt.vue";
	import {GetDepart,GetDepartDetail,GetTalentRecruitment} from '@/api/api'
	export default{
		components:{navheader,footercommon,bannerswipt},
		data(){
			return{
				info:[],
				infoList:[1,2,3,4],
				current:1,
				list:['就诊流程','来院指引'],
			}
		},
		mounted() {
			this.GetData();
		},
		methods:{
			changeActive(row,index){
				this.current=index;
				console.log(index)
				if(index==0){
					this.$router.push('/article3?guid=a6a9a559-5d84-4ee6-9524-2484525ccfc8')
				}else{
					this.$router.push('/hospitalGuide')
				}
			},
			// 获取接口数据
			GetData(){
				GetTalentRecruitment({guid:'c5c4008e-c5cf-4c43-9cc8-ccde9ce98fa8'}).then(res => {
					this.info=res.data.data.rows[0]
				})
			}
		}
	}
</script>

<style lang="scss">

</style>

<style lang="scss" scoped>
	@media (max-width:1200px) {
		
	}
	// img{
	// 	width: 22px;
	// 	height: 22px;
	// }
	// .boxstyle{
	// 	display: flex;
	// 	img{
	// 		margin-right: 6px;
	// 	}
	// 	.title{
	// 		font-size: 18px;
	// 		font-weight: 600;
	// 	}
	// 	.content{
	// 		font-size: 14px;
	// 		color: #666666;
	// 		line-height: 24px;
	// 		margin: 10px 0 0 10px;
	// 	}
	// }
	// .train{
	// 	display: inline-block;
	// 	color:#fff;
	// 	text-align: center;
	// 	line-height:36px;
	// 	width: 85px;
	// 	height: 36px;
	// 	background: #782076;
	// 	border-radius: 5px;
	// 	margin:20px 10px 0 0;
	// }
	// .bus{
	// 	display: inline-block;
	// 	text-align: center;
	// 	line-height:36px;
	// 	width: 85px;
	// 	height: 36px;
	// 	border-radius: 5px;
	// 	margin:20px 10px 10px 0;
	// 	border: 1px solid #E4E4E4;
	// 	font-size: 14px;
	// }
	
</style>

