<template>
	<div class="wrapBox">
		<navheader current="0"></navheader>
		<div class="contentbox">
			<div class="left">
				<div class="subnav" >
					<div v-for="(item,index) in subnav" :key="index" @click="subnavPath(item)">
						<img :src="item.url" alt="" width="44px" height="44px">
						<span>{{item.name}}</span>
					</div>
				</div>
				<div class="notice">
					<div class="subtitlebox">
						<span class="cn">协华公告</span>
						<span class="en">Notice</span>
					</div>
					<div v-for="(item,index) in noticeList" :key="index" class="noticeTitle" @click="pathDetail(item,1)">{{item.title}}</div>
					<div class="morebox" @click="path('/news?current=1')"><span>More</span></div>
				</div>
				<div >
					<img src="../assets/images/yyuan.png" alt="" width="210px" height="114px" style="border-radius:6px">
				</div>
				<div class="notice">
					<div class="subtitlebox">
						<span class="cn">媒体视点</span>
						<span class="en">Media</span>
					</div>
					<div v-for="(item,index) in noticeList2" :key="index" class="noticeTitle" @click="pathDetail(item,2)">{{item.title}}</div>
					<div class="morebox" @click="path('/news?current=2')"><span>More</span></div>
				</div>
			</div>
			<div class="right">
				<swipt></swipt>
				<div class="news">
					<div style="display: flex;justify-content: space-between;">
						<div class="subtitlebox">
							<span class="cn">图文新闻</span>
							<span class="en">NEWs</span>
						</div>
						<div class="morebox" @click="path('/news?current=0')"><span>More</span></div>
					</div>
					
					<div class="newlistbox">
						<smallSwipt></smallSwipt>
						<div class="newlist">
							<div v-for="(item,index) in newlist" :key="index" class="noticeTitle" @click="pathDetail(item,0)">> {{item.title}}</div>
							
						</div>
					</div>
				</div>
				
			</div>
		</div>
		<div class="contentbox1">
			<div style="display: flex;justify-content: space-between;border-bottom:1px solid #A41034;">
				<div class="subtitlebox">
					<span class="cn">医生介绍</span>
					<span class="en">Doctor Introduction</span>
				</div>
				<div class="morebox" @click="path('/doctlist')"><span>More</span></div>
			</div>
			<div class="departBox">
				<div class="departtitle" v-for="(item,index) in departlist" @click="changeDepartTab(index,item)">
					<span :class="{active:index==departCurrent}">{{item.hisdeptName}}</span>
				</div>
			</div>
			<div class="maincontent" style="min-height: auto;">
				<div class="itembox"  v-for="(item,index1) in doctorList" :key="index1"  @click="pathToDoctor(item)">
					<div>
						<img :src="$imgUrl+item.photoImg" alt="" class="itemboxImg">
					</div>
					<div class="content">
						<div class="textdetail">{{item.doctorName}}</div>
						<div class="textdetailSub" v-if="item.openInfo">坐诊信息：{{item.openInfo}}</div>
						<div class="textdetailSub">{{item.field}}</div>
					</div>
				</div>
				<div class="pagebox" v-if="conpages>1">
					<div class="pages" v-for="(item,index) in conpages" :key="index" @click="page(item)" :class="item==cur?'active':''" >{{item}}</div>
				</div>
			</div>
		</div>
		
		<footercommon></footercommon>
	</div>

</template>	
<script>
import navheader from "../components/navheader.vue";
import navheaderChild from "../components/navheaderChild.vue";
import footercommon from "../components/footercommon.vue";
import swipt from "../components/swipt.vue";
import smallSwipt from "../components/smallSwipt.vue";
import { GetDepart, GetDoctor, GetTalentRecruitment } from "@/api/api";
export default {
  components: { navheader, navheaderChild, footercommon, swipt,smallSwipt },
  data() {
    return {
		subnav:[
				{name:'门诊时间',url:require('../assets/images/门诊时间@2x.png')},
				{name:'门诊专家',url:require('../assets/images/专家@2x.png')},
				{name:'门诊导航',url:require('../assets/images/化验报告@2x.png')},
				{name:'学科介绍',url:require('../assets/images/预约挂号@2x.png')},
				{name:'出入院须知',url:require('../assets/images/出入院@2x.png')},
		],
		noticeList:[],
		noticeList2:[],
		departlist:[],
		departCurrent:0,
		departitem:['神经外科','手术科','普外科','骨科','泌尿外科','放射科','放射诊断科','麻醉科','放射治疗科','伽玛刀','危重病科'],
		newlist:[],
		active:0,
		doctorList:[],
    };
  },
  mounted() {
    this.GetData();
    this.getNew();
    this.getImg();
	this.getDoctor();
	if (this.isMobile()) {
	    // 执行移动端代码
		this.$router.push('/home_phone')
	  } else {
	    this.$router.push('/')
	}
  },
  methods: {
	isMobile() {
	    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		console.log(width)
	    return width < 768; // 假设屏幕宽度小于768px为移动设备
	},
    changeDepartTab(index, item) {
      this.departCurrent = index;
      this.getDoctor(item.hisdeptCode);
    },
    changeImg(index) {
      this.doctorCurrent = index;
    },
    pathToDoctor(row) {
      this.$router.push({
        path: "/doctorDetail",
        query: row,
      });
      window.scrollTo(0, 0);
    },
    path(url) {
      this.$router.push(url);
      window.scrollTo(0, 0);
    },
    GetData() {
      //获取科室
      GetDepart({ status: 1 }).then((res) => {
        this.departlist = res.data.data.rows;
        this.getDoctor(res.data.data.rows[0].hisdeptCode);
      });
    },
    getImg() {
      //获取医院环境图片
      GetTalentRecruitment({ categoryId: 1014, status: 1 }).then((res) => {
		this.hospitalImgList=res.data.data.rows
      });
    },
    getDoctor(hisdeptCode) {
      //获取医生
      GetDoctor({ status: 1,hisdeptCode:hisdeptCode }).then((res) => {
		  this.doctorList=[]
		console.log(this.doctorList,'this.doctorListthis.doctorList')
		res.data.data.rows.forEach((item, index) => {
		  if (index < 3) {
		    this.doctorList.push(item);
		  }
		});
      });
    },
    getNew() {
      //获取新闻咨询
      GetTalentRecruitment({ status: 1 ,pageSize:9999}).then((res) => {
		  console.log(res,'123455')
		  res.data.data.rows.forEach((item, index) => {
		    if (item.categoryId==6) {
		      this.newlist.push(item);
		    }
			if ( item.categoryId==13) {
			  this.noticeList.push(item);
			}
			if (item.categoryId==19) {
			  this.noticeList2.push(item);
			}
		  });
		  this.newlist=this.newlist.slice(0,10)
		  this.noticeList=this.noticeList.slice(0,5)
		   this.noticeList2=this.noticeList2.slice(0,5)
      });
    },
	subnavPath(item,url){
		if(item.name=='门诊时间'){
			this.$router.push({
			  path: "/article2",
			  query: {a:-1,b:'门诊时间',guid:"24786901-c659-4fd0-a01d-4f5ff8f524a0"},
			});
		}
		else if(item.name=='出入院须知'){
			this.$router.push({
			  path: "/article2",
			  query: {a:-1,b:'出入院须知',guid:"e6545a4e-e78e-4022-b1c4-518f7f063d28"},
			});
		}else if(item.name=='门诊专家'){
			this.$router.push({
			  path:'/doctlist'
			});
		}else if(item.name=='门诊导航'){
			this.$router.push({
			  path:'/guide'
			});
		}else{
			this.$router.push({
			  path:'/expertIntroducton'
			});
		}
	},
	pathDetail(row,index){
		// this.$router.push({
		//   path:'/article2'
		// });
		
		row={a:2,b:'新闻资讯',leftTitle:'新闻动态',current:index,...row}
		this.$router.push({
			path:'/article',
			query:row
		})
	}
  },
};
</script>
<style lang="scss" scoped>
.contentbox1{
	// border:2px solid #A41034;
	border-radius:10px;
	width: 1000px;
	margin: 0 auto;
	padding:20px 20px 0 20px;
	box-sizing: border-box;
}
.contentbox{
	width: 1000px;
	margin: 0 auto;
	padding:20px 20px 40px 20px;
	box-sizing: border-box;
	display: flex;
	.left{
		margin-right: 20px;
		width: 210px;
		.subnav{
			border-radius: 5px;
			border: 1px solid #E4E4E4;
			padding: 0 20px;
			div{
				cursor: pointer;
				display: flex;
				align-items: center;
				padding: 10px 0;
				border-bottom:1px solid #E4E4E4;
				&:last-child{border:none}
				span{
					margin-left: 14px;
				}
			}
		}
		.notice{
			margin: 20px;
			position:relative;
			font-size: 12px;
			.noticeTitle{
				cursor: pointer;
				width:160px;
				overflow:hidden;
				text-overflow:ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				color: #333333;
				line-height: 24px;
			}
		}
	}
	.right{
		.newlistbox{
			display: flex;
			.newlist{
				margin-left: 20px;
			}
		}
	}
}
.newlist{
	.noticeTitle{
		cursor: pointer;
		font-size: 12px;
		// width:160px;
		overflow:hidden;
		text-overflow:ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		color: #333333;
		line-height: 24px;
	}
}



.subtitlebox{
	color: #A41034;
	font-weight: 600;
	margin: 15px 0;
	.cn{font-size: 16px;}
	.en{font-size: 12px;margin-left: 6px;}
}
.morebox{
	cursor: pointer;
	text-align: right;
	span{
		display: inline-block;
		width: 46px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #A92429;
		color:#A92429;
		font-size: 12px;
		margin-top: 15px;
	}
}
.searchbox{
	display: flex;
	align-items: center;
	margin: 35px 0 30px 0;
	width: 730px;
	.lefttitle{
		width: 124px;
		height: 44px;
		line-height: 44px;
		background: #A92429;
		border-radius: 4px 0px 0px 4px;
		color:#fff;
		font-size: 16px;
		text-align: center;
	}
	.inputbox{
		width: 490px;
		border-top: 1px solid #E4E4E4;
		border-bottom: 1px solid #E4E4E4;
		height: 44px;
		line-height: 44px;
		padding-left: 35px;
		box-sizing: border-box;
		input{
			border:0;
			width: 100%;
			
			&:focus{
				outline:none;
			}
			&::-webkit-input-placeholder{
				color: #999999;
			}
		}
	}
	.righttitle{
		cursor: pointer;
		width: 124px;
		height: 42px;
		line-height: 42px;
		background: #F4F4F4;
		border-radius: 0px 4px  4px 0px; 
		border: 1px solid #E4E4E4;
		display: flex;
		align-items: center;
		justify-content: center;
		span{
			font-weight: 600;
			color: #A92429;
			font-size: 16px;
			margin-left: 8px;
		}
	}
}
.departBox{
	display: flex;
	margin-top:10px;
	.departtitle{
		font-size: 16px;
		font-weight: 600;
		span{
			cursor: pointer;
			display: inline-block;
			background-color: #aaa;
			padding:10px 20px;
			border-radius:10px 10px 0 0;
			color: #fff;
			&.active{
				background-color:#A41034;
			}
		}
		.line{
			display: block;
			width: 30px;
			height: 7px;
			background: #A41034;
			border-radius: 4px;
			margin:0 auto;
			margin-top: 10px;
		}
	}
	.departcontent{
		font-size: 14px;
		margin-top:20px;
		span{
			cursor: pointer;
			display: inline-block;
			width: 142px;
			margin-bottom: 20px;
		}
	}
}
.maincontent{
		
		padding:20px 10px 10px 10px;
		.textdetail{
					font-size:24px;
		}
		.textdetailSub{
			font-size: 14px;
			color:#999;
			margin-top:8px;
		}
		.itembox{
			border-bottom:1px solid #E4E4E4;
			display: flex;
			padding-bottom: 10px;
			margin-bottom: 10px;
			.itemboxImg{
				width: 100px;
				height: 125px;
			}
			.content{
				margin-left: 20px;
				position: relative;
				.time{
					position: absolute;
					bottom: 0;
					color:#999
				}
			}
		}
	}
</style>
<style lang="scss">
	.wrapBox {
	  height: 100vh;
	  overflow: auto;
	  scrollbar-color: transparent transparent;
	  &::-webkit-scrollbar {
	    display: none;
	  }
	}

</style>
