<template>
	<div class="wrapbox"  >  
	    <div class="footer">
	        <div class="top">
	            <a href="http://www.shgkh.com">友情链接：伽玛医院</a>
	            <a href="https://www.huashan.org.cn">华山医院</a>
	        </div>
	        <div class="mid">
	            <span>医院地址：上海市闵行区闵北路900号 </span> 
					<span>联系电话：021-34139999</span>
	        </div>
	        <div class="footernav">
	            <div style="cursor: pointer;" @click="path('https://beian.miit.gov.cn/#/Integrated/index')"> 沪ICP备2023009613号</div>
				<!-- <div style="display: flex;"> 
					<div class="item" ><img src="../assets/images/wangjin.png" > <span>上海网警网络110</span></div> 
					<div class="item" style="cursor: pointer;" @click="path('https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602000458')"><img src="../assets/images/guohui.png" > <span>沪公网安备 31010602000458号</span></div>
				</div>
	            <div style="display: flex;"> 
					<div class="item"><img src="../assets/images/gghui.png" alt=""> <span>上海工商</span> </div>
					<div class="item"><img src="../assets/images/zxinwnag.png" alt=""> <span>网络社会征信网</span> </div>
				</div> -->
	        </div>
			<div style="text-align: right;margin-top: 40px;font-size: 14px;">网站访问量：{{number}}人次</div>
	       <!-- <div class="imgcode">
				<img src="../assets/images/ercode.jpg" alt="">
				<div>微信扫一扫关注</div>
	        </div> -->
	    </div>
	</div>  
</template>

<script>

	import {GetTalentRecruitment,getNumber} from '@/api/api'

	export default{
		data(){
			return{
				info:{},
				number:0,
			}
		},
		mounted() {
			this.GetData();
			this.query()
		},
		methods:{
			GetData(){
				GetTalentRecruitment({categoryId: 16,status: 1}).then(res => {
					let guid=res.data.data.rows[0].guid
					GetTalentRecruitment({guid: guid}).then(res => {
					    this.info=res.data.data.rows[0]
						console.log(this.info)
					})
					
				})
			},
			query(){
				getNumber().then(res => {
					this.number=res.data.data.rows[0].website_visits
					
				})
			},
			path(url){
				window.location.href=url
			}
		}
	}
</script>


<style lang="scss" scoped>
	.wrapbox{
		width: 1000px;
		margin: 0 auto;
		background-color:#A41034;
		color:#ffffff;
		font-size: 12px;
		.footer{
			width: 1000px;
			padding:50px 68px 10px 68px;
			position: relative;
			box-sizing: border-box;
			.top{
				border-bottom:1px solid rgba(255,255,255,.5);
				padding-bottom: 20px;
				margin-bottom: 20px;
				font-size: 14px;
				a{
					color:#ffffff;
					margin-right: 65px;
					text-decoration: none;
					cursor: pointer;
					
				}
			}
			.mid{
				margin-bottom: 20px;
			}
			.imgcode{
				position: absolute;
				bottom:32px;
				right: 108px;
				text-align: center;
				img{
					width: 140px;
					height: 140px;
				}
			}
			.footernav{
				line-height: 30px;
				.item{
					margin-right: 40px;
					display: flex;
					align-items: center;
					img{
						margin-right: 6px;
					}
				}
			}
		}
	}
</style>
