<template>
	<div class="wrapBox">
		<navheader current="3"></navheader>
		<div class="contentbox">
			<bannerswipt class="banner"></bannerswipt>
			<div class="leftbox">
				<div class="redbox">
					<div class="china">门诊导航</div>
					<!-- <div class="english">Department</div> -->
				</div>
				<div class="borderbox" >
					<div class="listBox" >
						<div :class="{active:current==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
							<span>{{item}}</span>
							<div class="line"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="crumbs">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item>门诊导航</el-breadcrumb-item>
					  <el-breadcrumb-item >就诊流程</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="maincontent">
					<!-- <div class="title">{{info.title}}</div>
					<div class="time">发布时间：{{info.createTime}} </div> -->
					<!-- <img :src="$imgUrl+info.imagePath1" alt="" style="width: 56.2%;height: 300px;"> -->
					<div v-html="info.memo"></div>
				</div>
			</div>
		</div>
		<footercommon></footercommon>
	</div>
</template>

<script>
	import navheader from '../components/navheader.vue'
	import footercommon from '../components/footercommon.vue'
	import bannerswipt from "../components/bannerswipt.vue";
	import {GetTalentRecruitment} from '@/api/api'
	export default{
		components:{navheader,footercommon,bannerswipt},
		data(){
			return{
				current:0,
				current1:0,
				list:['就诊流程','来院指引'],
				info:{},
			}
		},
		mounted() {
			this.GetData();
		},
		methods:{
			GetData(){
				GetTalentRecruitment({guid:this.$route.query.guid}).then(res => {
					this.info=res.data.data.rows[0]
					console.log(this.info)
				})
			},
			changeActive(row,index){
				this.current=index;
				console.log(index)
				if(index==0){
					this.$router.push('/article3?guid=a6a9a559-5d84-4ee6-9524-2484525ccfc8')
				}else{
					this.$router.push('/hospitalGuide')
				}
			}
		}
	}
</script>

