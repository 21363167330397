<template>
	<div class="wrapBox" >
		<div class="phone_banner">
			<smallNavbar @current="current()"></smallNavbar>
		</div>
		<div class="phoneContent">
			<div class="tabBoxPhone"  v-if="$route.query.id==2">
				<div class="itembox" :class="{active:tabcurrent==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
					<span>{{item}}</span>
					<div class="line"></div>
				</div>
			</div>
			<div class="tabBoxPhone"  v-if="$route.query.id==6">
				<div class="itembox" :class="{active:tabcurrent==index}"  v-for="(item,index) in list2" :key="index" @click="changeActive2(item,index)">
					<span>{{item}}</span>
					<div class="line"></div>
				</div>
			</div>
			<div class="itembox"  v-for="(item,index1) in itemlist" :key="index1"  @click="path(item)">
				<div v-if="$route.query.id!=4">
					<img :src="$imgUrl+item.imagePath1" alt="" class="imagePath" v-if="item.imagePath1">
					<div v-else ></div>
				</div>
				<div class="content" v-if="$route.query.id!=4">
					<div class="textdetail">{{item.titleSub}}</div>
					<div class="textdetailSub">{{item.title}}</div>
					<div>发布时间：{{item.createTime}}</div>
				</div>
				<div class="content" v-else>
					<div class="textdetail">科室：{{item.titleSub}}</div>
					<div class="textdetailSub">岗位：{{item.title}}</div>
					<div class="textdetailSub">人数：{{item.imagePath1}}</div>
					<div>发布时间：{{item.createTime}}</div>
				</div>
			</div>
			<div class="pagebox" v-if="conpages>1">
				<div class="pages" v-for="(item,index) in conpages" :key="index" @click="page(item)" :class="item==cur?'active':''" >{{item}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import smallNavbar from "./components/smallNavbar.vue";
	import {GetTalentRecruitment} from '@/api/api'
	export default{
		components:{smallNavbar},
		data(){
			return{
				tabcurrent:0,
				tabcategoryId:'',
				list:["新闻咨询","协华公告","媒体视点"],
				list2:["科普专栏","视频中心"],
				itemlist:[],
				conpages:'',//总页数
				cur:1, //当前页
				pageSize:5, //每页要展示数据条数
			}
		},
		created() {
			this.GetData(1)
			
		},
		methods:{
			page(item){
				this.cur=item;  //当前页
				this.GetData(item)
			},
			GetData(pageNo){
				GetTalentRecruitment({categoryId:this.tabcategoryId||this.$route.query.categoryId ,status: 1,pageNo:pageNo,pageSize:this.pageSize}).then(res => {
					this.itemlist=res.data.data.rows
					this.conpages=Math.ceil(res.data.data.totalRows/this.pageSize)
					
				})
			},
			changeActive(row,index){
				this.tabcurrent=index;
				if(index==0){
					this.tabcategoryId=6
				}else if(index==1){
					this.tabcategoryId=13
				}else{
					this.tabcategoryId=19
				}
				this.GetData(1)
			},
			changeActive2(row,index){
				this.tabcurrent=index;
				if(index==0){
					this.tabcategoryId=9
				}else{
					this.tabcategoryId=14
				}
				this.GetData(1)
			},
			path(row){
				this.$router.push({
					path:'/article_phone',
					query:{...row,id:this.$route.query.id},
				})
			},
			current(e){
				this.tabcurrent=0
				this.tabcategoryId=""
				console.log(e,'eee')
				if(this.$route.query.id){
					this.GetData(1)
				}
			}
		}
	}
</script>

<style>
	.itembox{
		border-bottom:1px dashed #E4E4E4;
		padding-bottom:10px;
		margin-bottom:30px;
	}
	.imagePath{
		width: 100%;
		height: 196px;
	}
</style>