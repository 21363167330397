<template>
	<div class="wrapBox">
		<navheader current="1"></navheader>
		<div class="contentbox">
			<bannerswipt class="banner"></bannerswipt>
			<div class="leftbox">
				<div class="redbox">
					<div class="china">{{leftTitle}}</div>
					<!-- <div class="english">Introduce</div> -->
				</div>
				<div class="borderbox">
					<div class="listBox">
						<div :class="{active:current==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
							<span>{{item}}</span>
							<div class="line"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="crumbs">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item :to="{ path: '/hospital' }">{{leftTitle}}</el-breadcrumb-item>
					  <el-breadcrumb-item :to="{ path: '/hospital' }">{{list[current]}}</el-breadcrumb-item>
					  <el-breadcrumb-item >详情</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="maincontent">
					<img :src="$imgUrl+hospital.imagePath1"  style="width:200px;height:250px;">
					<div class="content" v-html="hospital.memo"></div>
				</div>
			</div>
		</div>
		<footercommon></footercommon>
	</div>
</template>

<script>
	import navheader from '../components/navheader.vue'
	import footercommon from '../components/footercommon.vue'
	import {GetTalentRecruitment,getDictionary} from '@/api/api'
	import bannerswipt from "../components/bannerswipt.vue";
	export default{
		components:{navheader,footercommon,bannerswipt},
		data(){
			return{
				leftTitle:'医院概况',
				hospital:{},
				current:2,
				list:[],
			}
		},
		mounted() {
			this.getDictionary()
			
		},
		methods:{
			//获取字典
			getDictionary() {
			  getDictionary({ bigCat: "bizOrderRegStatus",status: 1 }).then((res) => {
				  let a=res.data.data.rows.filter(val=>{return val.status==1});
			    this.list = a.map(val=>{return val.codeDesc});
				
				this.GetData()
			  });
			},
			GetData(){
				GetTalentRecruitment({guid:this.$route.query.guid}).then(res => {
					this.hospital=res.data.data.rows[0]
				})
			},
			changeActive(row,index){
				this.current=index;
				if(row=='医院环境'){
					this.$router.push('/Environment')
				}else{
					this.$router.push('/hospital')
					this.hospital={}
					this.$router.push({
						path:'/hospital',
						query:{current:index}
					})
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
	.maincontent{
		padding-top: 30px;
		display: flex;
		.content{
			margin-left:20px;
			width: 500px;
		}
	}
	
</style>
