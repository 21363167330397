<template>
	<div class="wrapBox">
		<navheader current="5"></navheader>
		<div class="contentbox">
			<bannerswipt class="banner"></bannerswipt>
			<div class="leftbox">
				<div class="redbox">
					<div class="china"> {{leftTitle}}</div>
					<!-- <div class="english">scientificResearch</div> -->
				</div>
				<div class="borderbox">
					<div class="listBox">
						<div :class="{active:current==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
							<span>{{item.hisdeptName}}</span>
							<div class="line"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="crumbs">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item > {{leftTitle}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="maincontent">
					<div class="itembox"  v-for="(item,index1) in itemlist" :key="index1"  @click="path(item)">
						<div>
							<img :src="$imgUrl+item.photoImg" alt="" class="itemboxImg">
						</div>
						<div class="content">
							<div class="textdetail">{{item.doctorName}}</div>
							<div class="textdetailSub" v-if="item.openInfo">坐诊信息：{{item.openInfo}}</div>
							<div class="textdetailSub">{{item.field}}</div>
							<!-- <div class="time">发布时间：{{item.createTime}}</div> -->
						</div>
					</div>
					<div class="pagebox" v-if="conpages>1">
						<div class="pages" v-for="(item,index) in conpages" :key="index" @click="page(item)" :class="item==cur?'active':''" >{{item}}</div>
					</div>
				</div>
			</div>
		</div>
		<footercommon></footercommon>
	</div>
</template>

<script>
	import navheader from '../components/navheader.vue'
	import footercommon from '../components/footercommon.vue'
	import bannerswipt from "../components/bannerswipt.vue";
	import {GetDepart,GetDoctor} from '@/api/api'
	export default{
		components:{navheader,footercommon,bannerswipt},
		data(){
			return{
				leftTitle:'专家介绍',
				current:0,
				list:[],
				itemlist:[],
				conpages:'',//总页数
				cur:1, //当前页
				pageSize:5, //每页要展示数据条数
				curhisdeptCode:'',//当前科室
			}
		},
		mounted() {
			if(this.$route.query.current){
				this.GetData(this.$route.query.current)
				this.cur=this.$route.query.current
			}else{
				this.query()
				// this.GetData(1)
			}
		},
		methods:{
			page(item){
				console.log()
				this.cur=item;  //当前页
				this.GetData(item,this.curhisdeptCode)
			},
			query(){
				GetDepart({status:1}).then(res => {
					this.list=res.data.data.rows;
					this.curhisdeptCode=res.data.data.rows[this.current].hisdeptCode
					this.GetData(1,res.data.data.rows[this.current].hisdeptCode);
				})
			},
			GetData(pageNo,hisdeptCode){
				GetDoctor({
					status: 1,
					pageNo:pageNo,
					pageSize:this.pageSize,
					hisdeptCode: hisdeptCode,
				}).then(res => {
					this.itemlist=res.data.data.rows
					this.conpages=Math.ceil(res.data.data.totalRows/this.pageSize)
					
				})
			},
			changeActive(row,index){
				this.cur=1;  //当前页
				console.log(row,'rowrowrow')
				this.current=index;
				this.curhisdeptCode=row.hisdeptCode
				this.GetData(1,row.hisdeptCode);
				
			},
			path(item){
				this.$router.push({
					path:'/doctorDetail',
					query:{guid:item.guid,current:this.cur}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.maincontent{
		padding:30px 10px;
		.textdetail{
					font-size:24px;
		}
		.textdetailSub{
			font-size: 14px;
			color:#999;
			margin-top:8px;
		}
		.itembox{
			border-bottom:1px solid #E4E4E4;
			display: flex;
			padding-bottom: 30px;
			margin-bottom: 30px;
			.itemboxImg{
				width: 100px;
				height: 125px;
			}
			.content{
				margin-left: 20px;
				position: relative;
				.time{
					position: absolute;
					bottom: 0;
					color:#999
				}
			}
		}
	}
</style>
 

