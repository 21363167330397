<template>
  <div class="wrapBox">
    <navheader current="8"></navheader>
    <!-- <img class="banner" src="../assets/images/banner2.png" alt="" /> -->
    <div class="contentbox">
    <bannerswipt class="banner"></bannerswipt>
      <div class="leftbox">
        <div class="redbox">
          <div class="china">{{leftTitle}}</div>
          <!-- <div class="english">Introduce</div> -->
        </div>
        <div class="borderbox">
          <div class="listBox">
            <div
              :class="{ active: current == index }"
              v-for="(item, index) in list"
              :key="index"
              @click="changeActive(item, index)"
            >
              <span>{{ item }}</span>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightbox">
        <div class="crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/anli' }">{{leftTitle}}</el-breadcrumb-item>
            <el-breadcrumb-item >{{list[current]}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="maincontent">
          <div
            class="itembox"
            v-for="(item, index1) in VideoList.slice(
              (pageNo - 1) * pageSize,
              pageSize * pageNo
            )"
            :key="index1"
            @click="path(item)"
          >
            <div>
            	<img :src="$imgUrl+item.imagePath1" alt="" class="itemboxImg" v-if="item.imagePath1">
            	<div class="itemboxImg" v-else></div>
            </div>
            <div class="content">
              <div class="textdetail">{{ item.title }}</div>
              <div class="textdetailSub">{{ item.titleSub }}</div>
              <div class="time">发布时间：{{ item.createTime }}</div>
            </div>
          </div>
        </div>
        <el-pagination
          class="pagebox"
          background
          :page-size="pageSize"
          layout="prev, pager, next"
          @current-change="changePageNo"
          :current-page="pageNo"
          :total="VideoList.length"
        >
        </el-pagination>
      </div>
    </div>

    <footercommon></footercommon>
  </div>
</template>

<script>
import navheader from "../components/navheader.vue";
import footercommon from "../components/footercommon.vue";
import bannerswipt from "../components/bannerswipt.vue";
import { GetTalentRecruitment} from "@/api/api";
export default {
  components: { navheader, footercommon,bannerswipt },
  data() {
    return {
	  leftTitle:'科普专栏',
      current: 1,
      list:['科普专栏','视频中心'],
      VideoList: [],
      pageNo: 1, //当前页
      totalRows: 4, //总条数
      pageSize: 3, //每页显示条数
      totalPage: 1, //总页数
    };
  },
  mounted() {
    this.GetVideoList();
  },
  methods: {
    changePageNo: function (val) {
      this.pageNo = val;
    },
    changeActive(row, index) {
      this.current=index;
      console.log(index)
      if(index==0){
      	this.$router.push('/anli')
      }else{
      	this.$router.push('/VideoList')
      }
    },
    path(row) {
      row = { a: 1,leftTitle:this.leftTitle, ...row };
      this.$router.push({
        path: "/VideoListDetail",
        query: row,
      });
    },
    GetVideoList() {
      GetTalentRecruitment({ categoryId: 14, status: 1 }).then((res) => {
        this.VideoList = res.data.data.rows;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapBox .banner {
  height: 240px;
}
.maincontent {
  padding: 30px 10px;
  min-height: 400px;
  box-sizing: border-box;
  .itembox {
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 30px;
    .itemboxImg {
      width: 178px;
      height: 125px;
    }
    .textdetail {
      line-height: 24px;
    }
    .content {
      margin-left: 20px;
      position: relative;
      .time {
        position: absolute;
        bottom: 0;
        right: 0;
        color: #999;
      }
    }
    .maincontent {
      .textdetail {
        font-size: 24px;
      }
      .textdetailSub {
        font-size: 14px;
        color: #999;
        margin-top: 8px;
      }
    }
  }
  .itembox:hover {
    cursor: pointer;
    .textdetail {
      color: red;
    }
  }
}

</style>
<style lang="scss">
.wrapBox{
			.contentbox{
				margin-top: 0 !important;
				.banner{
					margin-bottom: 40px !important;
				}
			}
		}
</style>
