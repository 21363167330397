
import axios from 'axios'
import { Message, Loading } from 'element-ui'
let loading;
//内存中正在请求的数量
let loadingNum = 0;
function startLoading() {
  if (loadingNum == 0) {
    loading = Loading.service({
      lock: true,
      text: '拼命加载中...',
      background: 'rgba(255,255,255,0.5)',
    })
  }
  //请求数量加1
  loadingNum++;
}

function endLoading() {
  //请求数量减1
  loadingNum--
  if (loadingNum <= 0) {
    loading.close()
  }
}
// 创建一个错误
function errorCreate(msg, code) {
  const error = new Error(msg)
  const errCode = code
  errorLog(error, errCode)
  // throw error
}

// 记录和显示错误
function errorLog(error, code) {
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    // util.log.danger('>>>>>> Error >>>>>>')
    // console.log(error)
  }
  // 显示提示
  // Message({
  //   message: error.message,
  //   type: 'error',
  //   duration: 5 * 1000
  // })
}

// 创建一个 axios 实例
const service = axios.create({
	baseURL: process.env.VUE_APP_API,
     // baseURL: 'http://172.16.30.2:12015',
	  // baseURL: 'https://wx.shxhbh.com',
  timeout: 100000 // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在请求发送之前做一些处理
    startLoading();
    return config
  },
  error => {
    // 发送失败
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    endLoading();
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data
    // 这个状态码是和后端约定的
    const { code } = dataAxios
    // 根据 code 进行判断
    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
      return response
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (code) {
        case 0:
          // [ 示例 ] code === 0 代表没有错误
          return response
        case 1:
          // [ 示例 ] code === 1代表有错误
          Message({
            type: "error",
            message: dataAxios.msg
          })
          return response
        case -1:
		  errorCreate(`${dataAxios.msg}`, dataAxios.code)
          return response
        case 1000:
          // [ 示例 ] 其它和后台约定的 code : ${response.config.url}
          errorCreate(`${dataAxios.msg}`, dataAxios.code)
          return response
        default:
          // 不是正确的 code : ${response.config.url}
          errorCreate(`${dataAxios.msg}`, dataAxios.code)
          return response
      }
    }
  },
  error => {
    endLoading();
    if (error && error.response) {
      switch (error.response.status) {
        case 400: error.message = '请求错误'; break
        case 401: error.message = '未授权，请登录'; break
        case 403: error.message = '拒绝访问'; break
        case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
        case 408: error.message = '请求超时'; break
        case 500: error.message = '服务器内部错误'; endLoading(); break
        case 501: error.message = '服务未实现'; break
        case 502: error.message = '网关错误'; break
        case 503: error.message = '服务不可用'; break
        case 504: error.message = '网关超时'; break
        case 505: error.message = 'HTTP版本不受支持'; break
        default: break
      }
    }
    errorLog(error)
    return Promise.reject(error)
  }
)

export default service
