import request from './index'
//获取人才招聘信息
export const GetTalentRecruitment = params => {
  return request({
    method: 'GET',
    url: '/api/dynamic/24257ac83cd34b9aa9d4c0bbf0217b44',
    params
  })
}

// 获取科室信息
export const GetDepart = params => {
  return request({
    method: 'GET',
    url: '/api/dynamic/4387eb6676974065a8514e9dc99bf405',
    params
  })
}

// 获取科室信息详情
export const GetDepartDetail = params => {
  return request({
    method: 'GET',
    url: '/api/dynamic/4387eb6676974065a8514e9dc99bf405',
    params
  })
}

// 获取科室下面的医生
export const GetDoctor = params => {
  return request({
    method: 'GET',
    url: '/api/dynamic/246744d34ea84cf98930d12cb0b1fe3a',
    params
  })
}

// 获取科室下面的医生
export const GetDoctorDetail = params => {
  return request({
    method: 'GET',
    url: '/api/dynamic/9969d03fd01c4ce292f5c779fd2ee1cc',
    params
  })
}


// 轮播图
export const GetImg = params => {
  return request({
    method: 'GET',
    url: '/api/dynamic/6e2da5bff5c44a16b36869d3aa05773e',
    params
  })
}

// 获取字典
export const getDictionary = params => {
  return request({
    method: 'GET',
    url: '/api/dynamic/1c750afb9e144985b73010f4b74bef59',
    params
  })
}
// 获取字典
export const getNumber = params => {
  return request({
    method: 'GET',
    url: '/api/dynamic/b3281492bcc14e97a34fb225eef4fc6b',
    params
  })
}

