<template>
	<div class="wrapBox">
		<navheader current="1"></navheader>
		<div class="contentbox">
			<bannerswipt class="banner"></bannerswipt>
			<div class="leftbox">
				<div class="redbox">
					<div class="china">{{$route.query.leftTitle}}</div>
					<!-- <div class="english">Introduce</div> -->
				</div>
				<div class="borderbox">
					<div class="listBox">
						<div :class="{active:current==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
							<span>{{item}}</span>
							<div class="line"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="crumbs">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item :to="{ path: '/anli' }">{{leftTitle}}</el-breadcrumb-item>
					  <el-breadcrumb-item :to="{ path:'/VideoList'}">视频中心</el-breadcrumb-item>
					  <el-breadcrumb-item >详情</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="maincontent">
					<div class="title">{{info.title}}</div>
					<div style="width: 100%;">
						<iframe :src="info.linkUrl" style="height: 500px;width:100%" scrolling="auto" border="0" frameborder="no" framespacing="0" allowfullscreen="true" ></iframe>
					</div>
					<div class="content" style="color:#999">{{info.titleSub}}</div>
				</div>
			</div>
		</div>
		<footercommon></footercommon>
	</div>
</template>

<script>
	import navheader from '../components/navheader.vue'
	import footercommon from '../components/footercommon.vue'
	import bannerswipt from "../components/bannerswipt.vue";
	import {GetTalentRecruitment} from '@/api/api'
	export default{
		components:{navheader,footercommon,bannerswipt},
		data(){
			return{
				// current:this.$route.query.a,
				leftTitle:'科普专栏',
				current:1,
				list:['科普专栏','视频中心'],
				info:{},
			}
		},
		mounted() {
			this.GetData();
		},
		methods:{
			GetData(){
				GetTalentRecruitment({guid:this.$route.query.guid}).then(res => {
					this.info=res.data.data.rows[0]
				})
			},
			changeActive(row,index){
				this.current=index;
				console.log(index)
				if(index==0){
					this.$router.push('/anli')
				}else{
					this.$router.push('/VideoList')
				}
			},
		}
	}
</script>

<style lang="scss" scoped>

	.maincontent{
		padding:30px 10px;
		.title{
			font-size: 20px;
			font-weight: bold;
			text-align: center;
		}
		.time{
			font-size: 14px;
			color:#666666;
			text-align: center;
			margin: 15px 0 30px;
			span{
				margin-left: 20px;
			}
		}
		.des{
			color: #666;
		}
		.video{
			width: 100%;
			height: 300px;
		}
	}
	
</style>

