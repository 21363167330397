<template>
	<div class="wrapBox" >
		<div class="phone_banner">
			<smallNavbar></smallNavbar>
		</div>
		<div class="phoneContent" >
			<div class="left" style="height:200px">
				<div class="imgbox">
					<img :src="$imgUrl+info.photoImg"  alt="" class="imgStyle">
				</div>
				<div class="doctorDes">
					<div class="name"><b>{{info.doctorName}}</b></div>
					<div class="zc">{{info.titleSub}}</div>
					<div class="zc">{{info.title}}</div>
					
				</div>
			</div>
			<div class="right">
				<div class="doctorCtxitem" style="display: flex;">坐诊信息：<div v-html="info.time"></div></div>
				<!-- <div class="doctorCtxitem" v-if="info.openInfo">坐诊信息：{{info.openInfo}}</div> -->
				<div class="doctorCtxitem" v-for="(item,index) in doctorCtx" :key="index">
					<div class="title"><img :src="item.icon" alt="" style="width: 20px;height: 20px;"><span>{{item.title}}</span></div>
					<div class="des" v-html="item.des"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import smallNavbar from "./components/smallNavbar.vue";
	import {GetDoctorDetail,GetDoctor} from '@/api/api'
	export default{
		components:{smallNavbar},
		data(){
			return{
				info:{},
				doctorCtx:[
					{icon:require("../../assets/images/expert_spe.png"),title:'诊疗特长',des:''},
					{icon:require("../../assets/images/expert_idcard.png"),title:'个人介绍',des:''},
					{icon:require("../../assets/images/guid_expert_pk.png"),title:'获得荣誉',des:''},
					{icon:require("../../assets/images/expert_major.png"),title:'从业经历',des:''},
				],
			}
		},
		created() {
			this.query(this.$route.query.guid);
			// this.getDoctor(this.$route.query.guid);
		},
		methods:{
			query(guid){
				GetDoctorDetail({guid: guid}).then(res => {
					
					this.doctorCtx[0].des=res.data.data.rows.field.replace(/\n/g,'<br>')
					this.doctorCtx[1].des=res.data.data.rows.memo.replace(/\n/g,'<br>')
					this.doctorCtx[2].des=res.data.data.rows.honor.replace(/\n/g,'<br>')
					this.doctorCtx[3].des=res.data.data.rows.experience.replace(/\n/g,'<br>')
					this.info=res.data.data.rows
					this.info.time=this.info.openInfo.replace(/\,/g,'<br>')
				})
			},
			getCurrent(item){
				this.query(item.guid);
				this.getDoctor(item.guid)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.left{
		display: flex;
		flex-direction: column;
		position: relative;
		.imgbox{
			z-index:2;
			position: absolute;
			left:32px;
			.imgStyle{
				width: 163px;
				height: 196px;
				border-radius: 10px;
				box-shadow: -10px -12px 0px #B30030; 
			}
		}
		.doctorDes{
			z-index:1;
			position: absolute;
			bottom:20px;
			left:0;
			width: 100%;
			height: 340px;
			background: #FFE0C7;
			border-radius: 10px;
			padding-top: 180px;
			box-sizing: border-box;
			padding-left: 38px;
			font-size: 16px;
			padding-left:210px;
			.name{
				
				b{
					font-size: 30px;
					color: #B30030;
					font-weight: 600;
					margin-right: 10px;
				}
			}
			.zc{
				margin-top: 10px;
			}
			.time{
				margin-top: 35px;
				display: flex;
				align-items: center;
				span{
					display: inline-block;
					width: 64px;
					height: 52px;
					background: #B30030;
					border-radius: 10px;
					color:#fff;
					font-size: 14px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 15px;
				}
			}
		}
	}
	.right{
		margin-top:40px;
		.doctorCtxitem{
			border-bottom: 1px dashed #E4E4E4;
			margin-bottom: 20px;
			padding:0 14px;
			padding-bottom: 20px;
			line-height: 28px;
			&:last-child{
				border:none;
			}
			.title{
				display: flex;
				align-items: center;
				span{
					margin-left: 6px;
					font-size: 14px;
				}
			}
			.des{
				color:#999;
				margin-top: 10px;
				font-size: 16px;
				text-align: justify;
			}
		}
		
	}

	
</style>



