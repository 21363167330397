import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userinfo:{},
	reId:"",
  },
  mutations: {
    SETUSERINFO(state,data){
      state.userinfo = data;
      window.localStorage.setItem('userinfo',JSON.stringify(state.userinfo))
    },
	SETREID(state,reId){
	  state.reId = reId;
	  window.localStorage.setItem('reId',state.reId)
	}
  },
  actions: {
	  load({commit}){
		  let userinfo =  window.localStorage.getItem('userinfo');
		  commit('SETUSERINFO',JSON.parse(userinfo));
		  
		  let reId =  window.localStorage.getItem('reId');
		  commit('SETREID',reId);
	  },
  },
  modules: {
  }
})
