<template>
  <div class="navChildbox">
    <div class="navBut" @click="Show()" v-if="!show">
      <i class="el-icon-menu"></i>
    </div>
    <div class="navHidden" @click="Show()" v-if="show">
      <i class="el-icon-close"></i> 
    </div>
    <div class="navmenu" v-if="show">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        unique-opened
      >
        <el-submenu v-for="(item, index) in navlist" :key="index"  :index="index+''">
          <template slot="title">
            <span>{{ item.title }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              @click="path(item1.url, index1)"
              v-for="(item1, index1) in item.content"
              :key="index1"
              :index="index + '-' + index1"
              >{{ item1.title }}</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { GetDepart } from "@/api/api";
export default {
  name: "navheaderChild",
  data() {
    return {
      show: true,
      flag: false,
      current1: "",
      navlist: [
        {
          title: "网站",
          url: "/",
          content: [{ title: "网站", url: "/" }],
        },
        {
          title: "医院简介",
          url: "/hospital",
          content: [
            { title: "医院介绍", url: "/hospital" },
            { title: "视频中心", url: "/VideoList" },
            { title: "医院环境", url: "/Environment" },
          ],
        },
        {
          title: "医疗特色",
          url: "/medicalFeatures",
          content: [
          { title: "放射诊断", url: "/medicalFeatures?type=0" },
          { title: "神经外科", url: "/medicalFeatures?type=1" },
          { title: "放射治疗", url: "/medicalFeatures?type=2" },
          { title: "神经内科", url: "/medicalFeatures?type=3" },
          ],
        },
        {
          title: "科室介绍  ",
          url: "/expertIntroducton",
          content: [],
        },
        {
          title: "典型病案",
          url: "/anli",
          content: [
            { title: "典型病历", url: "/anli" },
            { title: "经典荟萃", url: "/experience" },
          ],
        },
        {
          title: "就医指南",
          url: "/guide",
          content: [
            { title: "就诊流程", url: "/guide" },
            { title: "来院指引", url: "/hospitalGuide" },
          ],
        },
        {
          title: "人才招聘",
          url: "/talentRecruitment",
          content: [{ title: "人才招聘", url: "/talentRecruitment" }],
        },
      ],
      drpartCurrent: 0,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll, true), this.GetData();
    this.show=false;
  },
  mounted() {
    this.handle();
  },
  methods: {
    path(url, index) {
      this.show=!this.show
      // this.$router.push(url);
      // this.drpartCurrent = index;
      this.$emit("clickbtn", {
        index: index,
        url: url,
      });
    },
    handle(index) {
      this.current1 = index;
    },
    handleScroll() {
      this.$nextTick(() => {
        var top =
          document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageYOffset;
        if (top > 822) {
          this.flag = true;
        } else {
          this.flag = false;
        }
      });
    },
    Show() {
      this.show = !this.show;
    },
    pathTop() {
      window.scrollTo(0, 0);
    },
    GetData() {
      GetDepart({ status: 1 }).then((res) => {
        res.data.data.rows.forEach((item, index) => {
          this.navlist[3].content.push({
            title: item.deptName,
            url: "/expertIntroducton",
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navChildbox{
  background: white;
  color: gray;
}
.navHidden{
  text-align: center;
  position: fixed;
  z-index: 999;
  cursor:pointer;
  left: 10px;
  top:10px;
  i{
    font-size: 30px;
  }
}
.navBut{
  position: absolute;
  border: 1px solid #f1f1f1;
  top: 20px;
  z-index: 999;
  right: 10px;
  cursor:pointer;
  i{
    font-size: 40px;
  }
}
.navmenu {
  width: 100%;
  position:absolute;
  height: 1000px;
  top: 0;
  padding-top: 40px;
  background: white;
  right: 0;
  z-index: 888;
}
</style>
<style lang="scss" scoped>
@media (min-width: 1024px){
  .navChildbox{
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .navChildbox{
    display: none;
  }
}
@media (min-width: 375px) and (max-width: 768px) {
  .navChildbox{
    display: block;
  }
}
@media (max-width: 375px) {
  .navChildbox{
    display: block;
  }
}
</style>