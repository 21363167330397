<template>
	<div class="wrap">
		<div class="phone_banner">
			<smallNavbar @current="current()"></smallNavbar>
		</div>
		<div class="phoneContent">
			<div class="itembox"  v-for="(item,index1) in itemlist" :key="index1"  @click="path(item)">
				<div>
					<img :src="$imgUrl+item.photoImg" alt="" style="width:100px;height:125px">
				</div>
				<div style="margin-left:20px">
					<div class="textdetail">{{item.doctorName}}</div>
					<div class="textdetailSub" style="display: flex;">坐诊信息：<div v-html="item.time"></div></div>
					<div class="textdetailSub">{{item.field}}</div>
					<!-- <div class="time">发布时间：{{item.createTime}}</div> -->
				</div>
			</div>
			<div class="pagebox" v-if="conpages>1">
				<div class="pages" v-for="(item,index) in conpages" :key="index" @click="page(item)" :class="item==cur?'active':''" >{{item}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import smallNavbar from "./components/smallNavbar.vue";
	import {GetDoctor} from '@/api/api'
	export default{
		components:{smallNavbar},
		data(){
			return{
				itemlist:[],
				conpages:'',//总页数
				cur:1, //当前页
				pageSize:5, //每页要展示数据条数
			}
		},
		mounted() {
			this.GetData()
		},
		methods:{
			page(item){
				this.cur=item;  //当前页
				this.GetData(item)
			},
			GetData(pageNo){
				GetDoctor({status: 1,pageNo:pageNo,pageSize:this.pageSize}).then(res => {
					this.itemlist=res.data.data.rows.map(val=>{
						return {
							...val,
							time:val.openInfo.replace(/\,/g,'<br>')
						}
					})
					console.log(this.itemlist,'111')
					this.conpages=Math.ceil(res.data.data.totalRows/this.pageSize)
					
				})
			},
			path(item){
				this.$router.push({
					path:'/doctorDetail_phone',
					query:{guid:item.guid}
				})
			},
			current(){}
		}
	}
</script>

<style lang="scss" scoped>
	.textdetail{
				font-size:24px;
	}
	.textdetailSub{
		font-size: 14px;
		color:#999;
		margin-top:8px;
	}
	.itembox{
		border-bottom:1px solid #E4E4E4;
		display: flex;
		padding-bottom: 30px;
		margin-bottom: 30px;
		.content{
			margin-left: 20px;
			position: relative;
			.time{
				position: absolute;
				bottom: 0;
				color:#999
			}
		}
	}
</style>