import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/a_Home.vue'
import hospital from '../views/b_hospital.vue'

import medicalFeatures from '../views/c_MedicalFeatures.vue'
import expertIntroducton from '../views/d_expertIntroducton.vue'
import anli from '../views/e_anli.vue'
import experience from '../views/e_experience.vue'
import guide from '../views/f_guide.vue'
import hospitalGuide from '../views/f_hospitalGuide.vue'
import talentRecruitment from '../views/g_TalentRecruitment.vue'
import talentRecruitmentDetail from '../views/g_TalentRecruitmentDetail.vue'
import doctorDetail from '../views/z_doctorDetail.vue'
import news from '../views/z_news.vue'
import article from '../views/z_article.vue'
import article2 from '../views/z_article2.vue'
import article3 from '../views/z_article3.vue'
import VideoList from '../views/w_VideoList.vue'
import VideoListDetail from '../views/w_VideoListDetail.vue'
import Environment from '../views/w_environment.vue'
import education from '../views/education.vue'
import Publicity from '../views/Publicity.vue'
import doctlist from '../views/doctlist.vue'
import detail from '../views/b_detail.vue'

import home_phone from '../views/phone/home_phone.vue'
import article_phone from '../views/phone/article_phone.vue'
import doctorlist_phone from '../views/phone/doctorlist_phone.vue'
import doctorDetail_phone from '../views/phone/doctorDetail_phone.vue'
import experience_phone from '../views/phone/experience_phone.vue'
import list_phone from '../views/phone/list_phone.vue'
import hospital_phone from '../views/phone/hospital_phone.vue'
import kepu_phone from '../views/phone/kepu_phone.vue'





Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/doctorDetail',
    name: 'doctorDetail',
    component: doctorDetail
  },
  {
    path: '/doctlist',
    name: 'doctlist',
    component: doctlist
  },
  {
    path: '/Publicity',
    name: 'Publicity',
    component: Publicity
  },
  {
    path: '/education',
    name: 'education',
    component: education
  },
  {
    path: '/detail',
    name: 'detail',
    component: detail
  },
  {
    path: '/hospital',
    name: 'hospital',
    component: hospital
  },
  {
    path: '/medicalFeatures',
    name: 'medicalFeatures',
    component: medicalFeatures
  },
  {
    path: '/expertIntroducton',
    name: 'expertIntroducton',
    component: expertIntroducton
  },
  {
    path: '/anli',
    name: 'anli',
    component: anli
  },
  {
    path: '/experience',
    name: 'experience',
    component: experience
  },
  {
    path: '/guide',
    name: 'guide',
    component: guide
  },
  {
    path: '/hospitalGuide',
    name: 'hospitalGuide',
    component: hospitalGuide
  },
  {
    path: '/talentRecruitment',
    name: 'talentRecruitment',
    component: talentRecruitment
  },
  {
    path: '/talentRecruitmentDetail',
    name: 'talentRecruitmentDetail',
    component: talentRecruitmentDetail
  },
  {
    path: '/doctorDetail',
    name: 'doctorDetail',
    component: doctorDetail
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/article',
    name: 'article',
    component: article
  },
  {
    path: '/article2',
    name: 'article2',
    component: article2
  },
  {
    path: '/article3',
    name: 'article3',
    component: article3
  },
  {
    path: '/VideoList',
    name: 'VideoList',
    component: VideoList
  },
  {
    path: '/VideoListDetail',
    name: 'VideoListDetail',
    component: VideoListDetail
  },
  {
    path: '/Environment',
    name: 'Environment',
    component: Environment
  },
  //手机端地址
  {
    path: '/home_phone',
    name: 'home_phone',
    component: home_phone
  },
  {
    path: '/article_phone',
    name: 'article_phone',
    component: article_phone
  },
  {
    path: '/doctorlist_phone',
    name: 'doctorlist_phone',
    component: doctorlist_phone
  },
  {
    path: '/doctorDetail_phone',
    name: 'doctorDetail_phone',
    component: doctorDetail_phone
  },
  {
    path: '/experience_phone',
    name: 'experience_phone',
    component: experience_phone
  },
  {
    path: '/list_phone',
    name: 'list_phone',
    component: list_phone
  },
  {
    path: '/hospital_phone',
    name: 'hospital_phone',
    component: hospital_phone
  },
  {
    path: '/kepu_phone',
    name: 'kepu_phone',
    component: kepu_phone
  },
]

const router = new VueRouter({
  routes
})

export default router
