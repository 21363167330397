<template>
	<div class="wrapBox">
		<navheader current="1"></navheader>
		<div class="contentbox">
			<bannerswipt class="banner"></bannerswipt>
			<div class="leftbox">
				<div class="redbox">
					<div class="china">{{leftTitle}}</div>
					<!-- <div class="english">Introduce</div> -->
				</div>
				<div class="borderbox">
					<div class="listBox">
						<div :class="{active:current==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
							<span>{{item}}</span>
							<div class="line"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="crumbs">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item :to="{ path: '/hospital' }">{{leftTitle}}</el-breadcrumb-item>
					  <el-breadcrumb-item >{{list[current]}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="maincontent" v-if="text=='院领导介绍'" style="width:680px;margin-left:20px">
					<div class="leader" style="border-bottom: 2px dashed #f1f1f1;">
						<div v-for="(item,index) in leaderTop" :key="index" class="leaderItem" @click="seeDetail(item.guid)">
							<img :src="$imgUrl+item.imagePath1"  style="height: 188px;width:150px">
							<div class="subtitle"><span>{{item.titleSub}}</span></div>
						</div>
					</div>
					<div class="leader" style="justify-content: flex-start;">
						<div v-for="(item,index) in leaderBottom" :key="index" class="leaderItem" @click="seeDetail(item.guid)">
							<img :src="$imgUrl+item.imagePath1"  style="height: 188px;width:150px">
							<div class="subtitle"><span>{{item.titleSub}}</span></div>
						</div>
					</div>
				</div>
				<div class="maincontent" style="width:716px;"  v-else>
					<div class="titlename">{{hospital.title}}</div>
					<img :src="$imgUrl+hospital.imagePath1" class="imgStyle"  style="height: 300px;">
					<div class="content" style="width:716px;" v-html="hospital.memo"></div>
				</div>
			</div>
		</div>
		<footercommon></footercommon>
	</div>
</template>

<script>
	import navheader from '../components/navheader.vue'
	import footercommon from '../components/footercommon.vue'
	import {GetTalentRecruitment,getDictionary} from '@/api/api'
	import bannerswipt from "../components/bannerswipt.vue";
	export default{
		components:{navheader,footercommon,bannerswipt},
		data(){
			return{
				leftTitle:'医院概况',
				hospital:{},
				current:0,
				list:[],
				text:'',
				leaderTop:[],
				leaderBottom:[],
			}
		},
		mounted() {
			this.getDictionary()
			
			
		},
		methods:{
			//获取字典
			getDictionary() {
			  getDictionary({ bigCat: "bizOrderRegStatus",status: 1 }).then((res) => {
				  let a=res.data.data.rows.filter(val=>{return val.status==1});
					this.list = a.map(val=>{return val.codeDesc});
					
					if(this.$route.query.current){
						this.current=this.$route.query.current
						this.GetData(this.list[this.current])
					}else{
						this.GetData(this.list[this.current])
					}
			  });
			},
			GetData(text){
				this.text=text
				GetTalentRecruitment({categoryId: 7,status: 1}).then(res => {
					if(text!='院领导介绍'){
						let guid=res.data.data.rows.filter(val=> {return val.title==text})[0].guid
						GetTalentRecruitment({guid:guid}).then(res => {
							this.hospital=res.data.data.rows[0]
						})
					}else{
						this.leaderTop=res.data.data.rows.filter(val=> {return val.title==text &&  val.imagePath2=='一'})
						this.leaderBottom=res.data.data.rows.filter(val=> {return val.title==text &&  val.imagePath2=='二'})
					}
				})
			},
			changeActive(row,index){
				this.current=index;
				console.log(index)
				if(row=='医院环境'){
					this.$router.push('/Environment')
				}else{
					this.$router.push('/hospital')
					this.hospital={}
					this.GetData(row)
				}
			},
			seeDetail(guid){
				this.$router.push({
					path:'/detail',
					query:{guid:guid}
				})
			}
		}
	}
</script>

<style lang="scss">
	// 右边主要内容
	.maincontent{
		padding-top: 30px;
		.titlename{
			font-size: 24px;
			font-weight: 500;
		}
		.content{
			width: 916px;
		}
		.imgStyle{
			width: 56.2% !important;
			margin-top: 10px;
		}
	}
	.ql-align-center{
		text-align: center;
	}
	.ql-align-justify {
	  text-align: justify;
	}
	.ql-align-right {
	  text-align: right;
	}
	.ql-size-small {
	  font-size: 14px;
	}
	.ql-size-large{
		font-size: 18px;
	}
	.ql-size-huge{
		font-size: 32px;
	}
</style>
<style lang="scss" scoped>
	.maincontent{width: 800px;}
	.leader{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		.leaderItem{
			display: flex;
			flex-direction: column;
			justify-self: center;
			width: 220px;
			margin:20px 0;
			align-items: center;
			.subtitle{
				border-top:2px solid #f1f1f1;
				border-bottom:2px solid #f1f1f1;
				width: 150px;
				span{
					border-top:2px solid #fff;
					border-bottom:2px solid #fff;
					display: inline-block;
					text-align: center;
					background-color: #f1f1f1;
					color:#333;
					width: 150px;
					padding: 6px 0;
					font-size: 12px;
				}
			}
		}
	}
	
	::v-deep.maincontent img{
		max-width: 716px!important;
	}
	
</style>
