<template>
	<div class="wrapBox">
		<navheader current="6"></navheader>
		<div class="contentbox">
		<bannerswipt class="banner"></bannerswipt>
			<div class="leftbox">
				<div class="redbox">
					<div class="china">人才招聘</div>
					<!-- <div class="english">Job</div> -->
				</div>
				<div class="borderbox"></div>
			</div>
			<div class="rightbox">
				<div class="crumbs">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item :to="{ path: '/TalentRecruitment' }">人才招聘</el-breadcrumb-item>
					  <el-breadcrumb-item >详情</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				
				<!-- 表格 -->
				<table>
					<tr>
						<td>职位名称</td>
						<td>{{info.title}}</td>
					</tr>
					<tr>
						<td>科室</td>
						<td>{{filtertype(info.dictCode)}}</td>
					</tr>
					<tr>
						<td>招聘人数</td>
						<td>{{info.imagePath1}}</td>
					</tr>
					<tr>
						<td>内容要求</td>
						<td><div v-html="info.memo"></div></td>
					</tr>
					<tr>
						<td>备注</td>
						<td></td>
					</tr>
				</table>
			</div>
		</div>
		<footercommon></footercommon>
	</div>
</template>

<script>
	import navheader from '../components/navheader.vue'
	import footercommon from '../components/footercommon.vue'
	import bannerswipt from "../components/bannerswipt.vue";
	import {GetTalentRecruitment,getDictionary } from '@/api/api'
	export default{
		components:{navheader,footercommon,bannerswipt},
		data(){
			return{
				info:{},
				dictCodeList:[],
			}
		},
		mounted() {
			this.GetData()
			this.getDictionary()
		},
		methods:{
			GetData(){
				GetTalentRecruitment({guid: this.$route.query.guid,status: 1}).then(res => {
				    this.info=res.data.data.rows[0]
				})
			},
			//获取字典
			getDictionary() {
			  getDictionary({ bigCat: "zyDept",status: 1 }).then((res) => {
			    this.dictCodeList = res.data.data.rows;
			  });
			},
			//过滤类型
			filtertype(val){
			     let obj=this.dictCodeList.filter(el=>{
			           return el.code==val;
			     });
			     return obj.length ? obj[0].codeDesc :''
			},
			path(url){
				this.$router.push(url)
			}
		}
	}
</script>

<style lang="scss">
	table{
		margin-top: 30px;
		border:1px solid #E4E4E4;
		border-collapse: collapse;
		font-size: 16px;
		width: 100%;
		tr{
			td{
				border:1px solid #E4E4E4;
				padding:10px 30px;
				text-align: justify;
			}
			td:first-child{
				width: 230px;
				background: #F4F4F4;
				color:#999;
			}
		}
	}
	
</style>
