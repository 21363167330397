<template>
  <div class="wrapBox">
    <navheader current="1"></navheader>
    <div class="contentbox">
		<bannerswipt class="banner"></bannerswipt>
      <div class="leftbox">
        <div class="redbox">
          <div class="china">{{leftTitle}}</div>
          <!-- <div class="english">Introduce</div> -->
        </div>
        <div class="borderbox">
          <div class="listBox">
            <div
              :class="{ active: current == index }"
              v-for="(item, index) in list"
              :key="index"
              @click="changeActive(item, index)"
            >
              <span>{{ item }}</span>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightbox">
        <div class="crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/hospital' }"
              >{{leftTitle}}</el-breadcrumb-item
            >
            <el-breadcrumb-item>医院环境</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="maincontent">
          <div class="maincontentImg">
            <div class="ImgItem" v-for="(item, index) in hospital" :key="index">
			  <el-image 
				 class="small"
				:src="$imgUrl + item.imagePath1" 
				:preview-src-list="srcList">
			  </el-image>
              <div class="titlebox" v-if="item.title">
                <img src="../assets/images/index_position.png" alt="" style="width: 20px; height: 20px"/>
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footercommon></footercommon>
  </div>
</template>

<script>
import navheader from "../components/navheader.vue";
import footercommon from "../components/footercommon.vue";
import bannerswipt from "../components/bannerswipt.vue";
import { GetTalentRecruitment,getDictionary } from "@/api/api";
export default {
  components: { navheader, footercommon,bannerswipt },
  data() {
    return {
	  leftTitle:'医院概况',
      hospital: [],
      current: 3,
      list:[],
	  srcList: []
    };
  },
  mounted() {
	  this.getDictionary()
   
  },
  methods: {
	  //获取字典
	  getDictionary() {
	    getDictionary({ bigCat: "bizOrderRegStatus",status: 1 }).then((res) => {
	  	  let a=res.data.data.rows.filter(val=>{return val.status==1});
	      this.list = a.map(val=>{return val.codeDesc});
		  this.current=this.list.length-1
		  
		   this.GetData();
	    });
	  },
    GetData() {
      GetTalentRecruitment({ categoryId: 1014, status: 1 }).then((res) => {
        this.hospital = res.data.data.rows;
        console.log(this.hospital);
		this.srcList=this.hospital.map(val=>{
			return this.$imgUrl + val.imagePath1
		})
      });
    },
    changeActive(row, index) {
      this.current = index;
	  this.$router.push({
	  	path:'/hospital',
	  	query:{current:index}
	  })
    },
  },
};
</script>

<style lang="scss">
// 右边主要内容
.maincontent {
  padding-top: 30px;
  min-height: 400px;
  .titlename {
    font-size: 24px;
    font-weight: 500;
  }
  .content {
    width: 670px;
  }
  .imgStyle {
    width: 713px;
    margin-top: 10px;
  }
}

</style>
<style lang="scss" scoped>
.maincontent {
  width: 800px;
}

.maincontentImg {
  width: 713px;
  display: flex;
  flex-wrap: wrap;
  justify-content:space-between ;
  .small {
    width: 350px;
    height: 185px;
  }
  .ImgItem {
  margin-bottom: 7px;
  position: relative;
  .titlebox {
    position: absolute;
    bottom: 0;
    height: 52px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    line-height: 52px;
    text-align: center;
    color: #fff;
    display: none;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 10px;
      font-size: 18px;
    }
  }
  &:hover {
    .titlebox {
      display: block;
    }
  }
}
}

</style>
