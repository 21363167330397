<template>
	<div class="wrapBox">
		<navheader current="3"></navheader>
		<div class="contentbox">
			<bannerswipt class="banner"></bannerswipt>
			<div class="leftbox">
				<div class="redbox">
					<div class="china">门诊导航</div>
					<!-- <div class="english">Department</div> -->
				</div>
				<div class="borderbox">
					<div class="listBox">
						<div :class="{active:current==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
							<span>{{item}}</span>
							<div class="line"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="crumbs">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item>门诊导航</el-breadcrumb-item>
					  <el-breadcrumb-item :to="{ path: '/guide' }">就诊流程</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="maincontent">
					<div class="itembox" v-for="(item,index) in itemlist" :key="index" @click="path(item)">{{item.title}}</div>
				</div>
			</div>
		</div>
		<footercommon></footercommon>
	</div>
</template>

<script>
	import navheader from '../components/navheader.vue'
	import footercommon from '../components/footercommon.vue'
	import bannerswipt from "../components/bannerswipt.vue";
	import {GetTalentRecruitment} from '@/api/api'
	export default{
		components:{navheader,footercommon,bannerswipt},
		data(){
			return{
				info:{},
				current:0,
				list:['就诊流程','来院指引'],
				itemlist:[],
			}
		},
		mounted() {
			this.GetData()
		},
		methods:{
			GetData(){
				GetTalentRecruitment({categoryId: 10,status: 1}).then(res => {
					this.itemlist=res.data.data.rows
					
				})
			},
			changeActive(row,index){
				this.current=index;
				console.log(index)
				if(index==0){
					this.$router.push('/article3?guid=a6a9a559-5d84-4ee6-9524-2484525ccfc8')
				}else{
					this.$router.push('/hospitalGuide')
				}
			},
			path(row){
				this.$router.push({
					path:'/article3',
					query:row
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.maincontent{
		width: 700px;
		min-height: 400px;
		margin: 20px 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.itembox{
			width: 264px;
			height: 57px;
			border: 1px solid #E4E4E4;
			font-size: 18px;
			font-weight: 400;
			text-align: center;
			line-height: 57px;
			margin-bottom:55px ;
			cursor: pointer;
			&:hover{
				background: #A41034;
				color:#fff;
			}
		}
	}
	
	
</style>
