<template>
  <div class="wrapBox">
    <navheader current="4"></navheader>
    <div class="contentbox">
    <bannerswipt class="banner"></bannerswipt>
      <div class="leftbox">
        <div class="redbox">
          <div class="china">典型病案</div>
          <!-- <div class="english">Case</div> -->
        </div>
        <div class="borderbox">
          <div class="listBox">
            <div
              :class="{ active: current == index }"
              v-for="(item, index) in list"
              :key="index"
              @click="changeActive(item, index)"
            >
              <span>{{ item }}</span>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="rightbox">
        <div class="crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/anli' }"
              >典型病案</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/experience' }"
              >经典荟萃</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="maincontent">
          <div
            class="itembox"
            v-for="(item, index1) in itemlist"
            :key="index1"
            @click="path(item)"
          >
            <div>
              <img :src="$imgUrl + item.imagePath1" alt="" class="itemboxImg" />
            </div>
            <div class="content">
              <div class="textdetail">{{ item.titleSub }}</div>
              <div class="textdetailSub">{{ item.title }}</div>
              <div class="time">发布时间：{{ item.createTime }}</div>
            </div>
          </div>
          <div class="pagebox" v-if="conpages > 1">
            <div
              class="pages"
              v-for="(item, index) in conpages"
              :key="index"
              @click="page(item)"
              :class="item == cur ? 'active' : ''"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <footercommon></footercommon>
  </div>
</template>

<script>
import navheader from "../components/navheader.vue";
import footercommon from "../components/footercommon.vue";
import bannerswipt from "../components/bannerswipt.vue";
import { GetTalentRecruitment} from "@/api/api";
export default {
  components: { navheader, footercommon,bannerswipt },
  data() {
    return {
      current: 1,
      list: ['经验荟萃','典型病案'],
      itemlist: [],

      conpages: "", //总页数
      cur: 1, //当前页
      pageSize: 5, //每页要展示数据条数
    };
  },
  mounted() {
    this.GetData(1);
    this.page(this.cur);
  },
  methods: {
    page(item) {
      this.cur = item; //当前页
      this.GetData(item);
    },
    GetData(pageNo) {
      GetTalentRecruitment({
        categoryId: 9,
        pageNo: pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        this.itemlist = res.data.data.rows;
        this.conpages = Math.ceil(res.data.data.totalRows / this.pageSize);
      });
    },
    changeActive(row, index) {
      this.current = index;
      console.log(index);
      if (index == 0) {
        this.$router.push("/anli");
      } else {
        this.$router.push("/experience");
      }
    },
    path(row) {
      row = { a: 1, b: "经典荟萃", ...row };
      this.$router.push({
        path: "/article2",
        query: row,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.maincontent {
  min-height: 400px;
  .textdetail {
    font-size: 24px;
  }
  .textdetailSub {
    font-size: 14px;
    color: #999;
    margin-top: 8px;
  }
  padding: 30px 10px;
  .itembox {
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    padding-bottom: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    .itemboxImg {
      width: 178px;
      height: 125px;
    }
    .content {
      margin-left: 20px;
      position: relative;
      .time {
        position: absolute;
        bottom: 0;
        color: #999;
      }
    }
  }
}

</style>
<style lang="scss">
.pagebox {
  display: flex;
  justify-content: flex-end;
  .pages {
    width: 30px;
    height: 30px;
    background-color: #999;
    color: #fff;
    margin-left: 10px;
    text-align: center;
    line-height: 30px;
    &.active {
      background-color: #a41034;
    }
  }
}
</style>
