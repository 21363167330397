<template>
	<div class="wrapBox">
		<navheader current="4"></navheader>
		<div class="contentbox">
		<bannerswipt class="banner"></bannerswipt>
			<div class="leftbox">
				<div class="redbox">
					<div class="china">医疗特色</div>
					<!-- <div class="english">Characteristic</div> -->
				</div>
				<div class="borderbox">
					<div class="listBox">
						<div :class="{active:current==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
							<span>{{item.title}}</span>
							<div class="line"></div>
						</div>
						<!-- <div>放射诊断</div>
						<div>放射治疗</div> -->
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="crumbs">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item :to="{ path: '/MedicalFeatures' }">医疗特色</el-breadcrumb-item>
					  <el-breadcrumb-item >{{info.title}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="maincontent">
					<div class="titlename">{{info.title}}</div>
					<img :src="$imgUrl+info.imagePath1" class="imgStyle">
					<div class="content" v-html="info.memo" ></div>
				</div>
			</div>
		</div>
		<footercommon></footercommon>
	</div>
</template>

<script>
	import navheader from '../components/navheader.vue'
	import footercommon from '../components/footercommon.vue'
	import bannerswipt from "../components/bannerswipt.vue";
	import {GetTalentRecruitment} from '@/api/api'
	export default{
		components:{navheader,footercommon,bannerswipt},
		data(){
			return{
				info:{},
				list:[],
				current:0,
			}
		},
		mounted() {
			this.active=0;
			if(this.$route.query.type){
				this.active = this.$route.query.type
			}
			this.GetData()
		},
		methods:{
			GetData(){
				GetTalentRecruitment({categoryId: 5,status:1}).then(res => {
					this.list=res.data.data.rows;
					this.getDetail(res.data.data.rows[this.active].guid);
					
				})
			},
			getDetail(guid){
				//获取详细内容
				GetTalentRecruitment({guid: guid,status: 1}).then(res => {
				    this.info=res.data.data.rows[0]
					 
				})
			},
			changeActive(row,index){
				this.current=index;
				this.getDetail(row.guid)
			}
		}
	}
</script>


