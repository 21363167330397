<template>
	<div class="wrapBox">
		<navheader current="2"></navheader>
		<div class="contentbox">
			<bannerswipt class="banner"></bannerswipt>
			<div class="leftbox">
				<div class="redbox">
					<div class="china">{{leftTitle}}</div>
					<!-- <div class="english">News</div> -->
				</div>
				<div class="borderbox">
					<div class="listBox">
						<div :class="{active:current==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
							<span>{{item}}</span>
							<div class="line"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="crumbs">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item :to="{ path: '/news' }">{{leftTitle}}</el-breadcrumb-item>
					  <el-breadcrumb-item >{{list[current]}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="maincontent">
					<div class="itembox"  v-for="(item,index1) in itemlist" :key="index1"  @click="path(item)">
						<div>
							<img :src="$imgUrl+item.imagePath1" alt="" class="itemboxImg" v-if="item.imagePath1">
							<div class="itemboxImg" v-else></div>
						</div>
						<div class="content">
							<div class="textdetail">{{item.titleSub}}</div>
							<div class="textdetailSub">{{item.title}}</div>
							<div class="time">发布时间：{{item.createTime}}</div>
						</div>
					</div>
					<div class="pagebox" v-if="conpages>1">
						<div class="pages" v-for="(item,index) in conpages" :key="index" @click="page(item)" :class="item==cur?'active':''" >{{item}}</div>
					</div>
				</div>
			</div>
		</div>
		<footercommon></footercommon>
	</div>
</template>

<script>
	import navheader from '../components/navheader.vue'
	import footercommon from '../components/footercommon.vue'
	import bannerswipt from "../components/bannerswipt.vue";
	import {GetTalentRecruitment} from '@/api/api'
	export default{
		components:{navheader,footercommon,bannerswipt},
		data(){
			return{
				leftTitle:'新闻动态',
				current:this.$route.query.current||0,
				list:['新闻咨询','协华公告','媒体视点'],
				itemlist:[],
				
				conpages:'',//总页数
				cur:1, //当前页
				pageSize:5, //每页要展示数据条数
			}
		},
		mounted() {
			this.GetData(1)
		},
		methods:{
			page(item){
				this.cur=item;  //当前页
				this.GetData(item)
			},
			GetData(pageNo){
				let categoryId='';
				if(this.current==0){
					categoryId=6
				}else if(this.current==1){
					categoryId=13
				}else{
					categoryId=19
				}
				GetTalentRecruitment({categoryId: categoryId,pageNo:pageNo,pageSize:this.pageSize}).then(res => {
					this.itemlist=res.data.data.rows
					this.conpages=Math.ceil(res.data.data.totalRows/this.pageSize)
				})
				
			},
			changeActive(row,index){
				this.current=index;
				this.GetData(1)
			},
			path(row){
				row={a:2,b:'新闻资讯',leftTitle:'新闻动态',current:this.current,...row}
				this.$router.push({
					path:'/article',
					query:row
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.maincontent{
		padding:30px 10px;
		.textdetail{
					font-size:24px;
		}
		.textdetailSub{
			font-size: 14px;
			color:#999;
			margin-top:8px;
		}
		.itembox{
			border-bottom:1px solid #E4E4E4;
			display: flex;
			padding-bottom: 30px;
			margin-bottom: 30px;
			.itemboxImg{
				width: 178px;
				height: 125px;
			}
			.content{
				margin-left: 20px;
				position: relative;
				.time{
					position: absolute;
					bottom: 0;
					color:#999
				}
			}
		}
	}
</style>
 