<template>
	<div class="w1000">
		<div class="headerBox">
			<a @click="jump"><img class="logo" src="../assets/images/logotext.png" /></a>
			<!-- -->
			<div class="rightbox">
				<div class="itemboxH" @mousemove="showcode=true" @mouseleave="showcode=false">
					<img src="../assets/images/ercode.jpg" alt="" width="60px" height="60px">
					<!-- <div>医院公众号</div> -->
				</div>
				<div>
					
				</div>
				<div class="imgcode" v-if="showcode">
					<img src="../assets/images/图片1.png" alt="">
				 </div>
				<div class="line"></div>
				
				<div  class="itemboxH">
					<img src="../assets/images/phone.jpg" alt="" width="170px" height="40px">
					<!-- <img src="../assets/images/微信@2x.png" alt="" width="30px" height="30px"> -->
					<!-- <div>互联网医院</div> -->
				</div>
			</div>
			<!-- <navheaderChild @clickbtn="clickbtn"></navheaderChild> -->
		</div>
		<div class="kong"></div>
		<div class="navbox">
			<div class="navContent">
				<div v-for="(item,index) in navlist" :key="index" @click="path(item.url)" @mouseover="handle(index)" @mouseout="current1=''">
					<span>{{item.title}}</span>
					<div class="line" v-if="current==index"></div>
					<div class="secondbox" v-show="current1==index">
						<div v-for="(item1,index1) in item.content" :key="index1" @click.stop="path(item1.url,index1)">{{item1.title}}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="righttel" v-if="flag" ref="wrap">
		    <div><img  src="../assets/images/tel.png" alt=""></div>
		    <div @click="pathTop()"><img  src="../assets/images/top24.png" alt=""></div>
		    <div><img src="../assets/images/xinxi24.png" alt=""></div>
		</div> -->
	</div>
</template>

<script>
	import {GetDepart} from '@/api/api'
import navheaderChild from './navheaderChild.vue';
	
	export default{
		name: 'navheader',
  components: { navheaderChild },
		props:['current'],
		data(){
			return{
				showcode:false,
				flag:false,
				current1:'',
				navlist:[
					{title:'首页',url:'/'},
					{title:'医院概况',url:'/hospital',
					// content:[
					// 	{title:'医院介绍',url:'/hospital'},
					// 	{title:'发展历程',url:'/hospital'},
					// 	{title:'院领导介绍',url:'/hospital'},
					// 	{title:'医院环境',url:'/Environment'},
					// ],
					},
					{title:'新闻动态',url:'/news'},
					{title:'门诊导航',url:'/article3?guid=a6a9a559-5d84-4ee6-9524-2484525ccfc8'},
					{title:'学科介绍',url:'/expertIntroducton'},
					{title:'专家介绍',url:'/doctlist'},
					{title:'科研教育',url:'/education'},
					{title:'人才招聘',url:'/talentRecruitment'},
					{title:'科普专栏',url:'/anli'},
					{title:'院务公开',url:'/Publicity'},
					{title:'专题网站',url:''},
					// {title:'科室介绍  ',url:'/expertIntroducton',content:[]},
					// {title:'典型病案',url:'/anli',content:[
					// 	{title:'典型病历',url:'/anli'},
					// 	{title:'经典荟萃',url:'/experience'},
					// ]},
					// {title:'就医指南',url:'/guide',content:[
					// 	{title:'就诊流程',url:'/guide'},
					// 	{title:'来院指引',url:'/hospitalGuide'},
					// ]},
					// {title:'人才招聘',url:'/talentRecruitment'}
				],
				drpartCurrent:0,
			}
		},
		created() {
			window.addEventListener('scroll', this.handleScroll, true),
			this.GetData();
		},
		methods:{
			jump(){
				this.$router.push('/');
			},
			clickbtn(data) {
				this.path(data.url, data.index);
				if(data.url == '/medicalFeatures?type=0') location.reload()
				if(data.url == '/medicalFeatures?type=1') location.reload()
				if(data.url == '/medicalFeatures?type=2') location.reload()
				if(data.url == '/medicalFeatures?type=3') location.reload()
				
			},
			path(url,index){
				if(url == '/expertIntroducton') {
					this.$router.push(url + '?type=' + index)
					location.reload()
				} else {
					this.$router.push(url)
				}

				this.drpartCurrent=index
				this.$emit('clickbtn',this.drpartCurrent)
			},
			handle(index){
				this.current1=index
			},

			handleScroll(){
				this.$nextTick(()=>{
					var top = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
					console.log(document.body.scrollTop)
					if(top >822){
						this.flag=true
					}else{
						this.flag=false
					}
				})
			},
			pathTop(){
				window.scrollTo(0,0);
			},
			GetData(){
				GetDepart({status:1}).then(res => {
					res.data.data.rows.forEach((item,index)=>{
						this.navlist[3].content.push({
							title:item.deptName,
							url:'/expertIntroducton'
						})
					})
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.headerBox{
		margin:0 auto;
		width: 1000px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding:20px 30px 20px 0;    
		box-sizing: border-box;
		.logo{
			width: 400px;
			height:66px ;
		}
		.rightbox{
			position: relative;
			display: flex;
			font-weight: 600;
			color: #999999;
			font-size: 16px;
			align-items: center;
			border-radius: 10px;
			border: 1px solid #E4E4E4;
			.line{
				width: 1px;
				height: 20px;
				border-right: 1px solid #E4E4E4;
			}
			.itemboxH{
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 10px 20px;
			}
			
		}
	}
	.navbox{
		width: 1000px;
		margin: 0 auto;
		height:44px;
		background-color:#A41034 ;
		color:#fff;
		.navContent{
			display: flex;
			div{
				cursor: pointer;
				flex:1;
				text-align: center;
				position: relative;
				span{
					display: inline-block;
					line-height: 40px;
					font-size: 14px;
				}
				.line{
					width: 45px;
					height: 2px;
					border-radius: 2px;
					background-color: #fff;
					position: absolute;
					bottom:2px;
					left: 50%;
					margin-left: -22px;
				}
				.secondbox{
					// display: none;
					position: absolute;
					top:38px;
					width: 120px;
					background: #A41034;
					border-radius: 5px;
					color:#fff;
					left: 50%;
					margin-left: -60px;
					padding:0 20px;
					box-sizing: border-box;
					z-index:999;
					font-size: 14px;
					div{
						line-height: 50px;
						border-bottom:1px solid #e4e4e4;
						&:last-child{
							border:none;
						}
						&:hover{
							color:#eb1f51;
						}
					}
				}
			}
		}
	}
	.righttel{
		position: fixed;
		z-index:999;
		bottom:10px;
		right: 23px;
		cursor: pointer;
	}
	.imgcode{
		position: absolute;
		bottom:-126px;
		text-align: center;
		z-index:999;
		img{
			width: 120px;
			height: 120px;
		}
	}
</style>
