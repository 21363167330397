<template>
	<div class="wrapBox" >
		<div class="phone_banner">
			<smallNavbar @current="current()"></smallNavbar>
		</div>
		<div class="phoneContent">
			<div class="tabBoxPhone" >
				<div class="itembox" :class="{active:tabcurrent==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
					<span>{{item}}</span>
					<div class="line"></div>
				</div>
			</div>
			<div v-if="tabcurrent==0">
				<div class="title">{{hospital.title}}</div>
				<div class="time">发布时间：{{hospital.createTime}} </div>
				<img :src="$imgUrl+hospital.imagePath1" alt="" style="width: 100%;height: 300px;" v-if="hospital.imagePath1">
				<div class="content" v-html="hospital.memo"></div>
			</div>
			<div v-if="tabcurrent==1">
				<div class="title">{{hospital.title}}</div>
				<div class="time">发布时间：{{hospital.createTime}} </div>
				<img :src="$imgUrl+hospital.imagePath1" alt="" style="width: 100%;height: 300px;" v-if="hospital.imagePath1">
				<div class="content" style="width:350px;" v-html="hospital.memo"></div>
			</div>
			<div class="maincontentImg" v-if="tabcurrent==2">
			  <div class="ImgItem" v-for="(item, index) in itemlist" :key="index">
					 <el-image 
						 class="small"
						:src="$imgUrl + item.imagePath1" 
						:preview-src-list="srcList">
					  </el-image>
			    <div class="titlebox" v-if="item.title">
			      <img src="../../assets/images/index_position.png" alt="" style="width: 20px; height: 20px"/>
			      <span>{{ item.title }}</span>
			    </div>
			  </div>
			</div>
		</div>
	</div>
</template>

<script>
	import smallNavbar from "./components/smallNavbar.vue";
	import {GetTalentRecruitment} from '@/api/api'
	export default{
		components:{smallNavbar},
		data(){
			return{
				tabcurrent:0,
				tabcategoryId:'',
				list:["医院介绍","发展历程","医院环境"],
				itemlist:[],
				conpages:'',//总页数
				cur:1, //当前页
				pageSize:5, //每页要展示数据条数
				hospital:{},
				 srcList: []
			}
		},
		created() {
			this.GetData1()
		},
		methods:{
			page(item){
				this.cur=item;  //当前页
				this.GetData(item)
			},
			GetData1(){
				this.hospital=[]
				GetTalentRecruitment({guid: '1AB94A57-8D7D-466C-BBFC-2B8D49759562'}).then(res => {
				    this.hospital=res.data.data.rows[0]
					console.log(this.hospital)
				})
			},
			GetData2(){
				this.hospital=[]
				GetTalentRecruitment({guid: '3307074c-fa41-4e2b-8c6e-b29a4c85c4ef'}).then(res => {
				    this.hospital=res.data.data.rows[0]
					console.log(this.hospital)
				})
			},
			GetData(categoryId,pageNo){
				GetTalentRecruitment({categoryId:categoryId ,status: 1,pageNo:pageNo,pageSize:this.pageSize}).then(res => {
					this.itemlist=res.data.data.rows
					this.conpages=Math.ceil(res.data.data.totalRows/this.pageSize)
					if(categoryId=='1014'){
						this.srcList=this.itemlist.map(val=>{
							return this.$imgUrl + val.imagePath1
						})
					}
				})
			},
			changeActive(row,index){
				this.tabcurrent=index;
				if(index==0){
					this.GetData1('1AB94A57-8D7D-466C-BBFC-2B8D49759562')
				}else if(index==1){
					this.GetData2()
				}else{
					this.GetData('1014',1)
				}
			},
			path(row){
				this.$router.push({
					path:'/article_phone',
					query:{...row,id:this.$route.query.id},
				})
			},
		}
	}
</script>

<style>
	.itembox{
		border-bottom:1px dashed #E4E4E4;
		padding-bottom:10px;
		margin-bottom:30px;
	}
	.imagePath{
		width: 100%;
		height: 196px;
	}
</style>
<style lang="scss" scoped>
	.maincontentImg {
	  width: 100%;
	  display: flex;
	  flex-wrap: wrap;
	  justify-content:center ;
	  .small {
	    width: 350px;
	    height: 185px;
	  }
	  .ImgItem {
	  margin-bottom: 7px;
	  position: relative;
	  .titlebox {
	    position: absolute;
	    bottom: 0;
	    height: 52px;
	    width: 100%;
	    background-color: rgba(0, 0, 0, 0.6);
	    line-height: 52px;
	    text-align: center;
	    color: #fff;
	    display: none;
	    align-items: center;
	    justify-content: center;
	    span {
	      margin-left: 10px;
	      font-size: 18px;
	    }
	  }
	  .titlebox {
	    display: block;
	  }
	}
	}
	::v-deep.content img{
		max-width: 350px!important;
	}
</style>