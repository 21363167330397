<template>
	<div class="wrapBox">
		<navheader current="4" ></navheader>
		<div class="contentbox">
			<bannerswipt class="banner"></bannerswipt>
			<div class="leftbox">
				<div class="redbox">
					<div class="china">学科介绍</div>
					<!-- <div class="english">Department</div> -->
				</div>
				<div class="borderbox">
					<div class="listBox">
						<div :class="{active:current==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
							<span>{{item.hisdeptName}}</span>
							<div class="line"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="crumbs">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item :to="{ path: '/expertIntroducton' }">科室介绍</el-breadcrumb-item>
					  <el-breadcrumb-item >{{info.hisdeptName}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="maincontent" style="min-height: 440px;">
					<div class="departIntroduct">
						<img :src="$imgUrl+info.photoImg" alt="" class="departIntroductImg" style="width: 342px;height: 193px;" v-if="info.photoImg">
						<div class="departContent">
							<div class="departTitle">{{info.deptName}}科室简介</div>
							<div class="departDes"  v-html="info.deptIntroduction"></div>
						</div>
					</div>
					<!-- <el-carousel :interval="8000"  height="240px" indicator-position="outside" @change="setActiveItem">
					    <el-carousel-item  v-for="item in conpages" :key="item">
							<div class="doctorlist">
								<div class="doctorItem" v-for="(item,index) in showPage" :key="index" @click="path(item)">
									<img :src="$imgUrl+item.photoImg"  alt="" style="width: 159px;height: 181px;">
									<div>
										<span class="name">{{item.doctorName}}</span>
										<span class="zhicheng">{{item.title}}</span>
									</div>
								</div>
							</div>
					    </el-carousel-item>
					</el-carousel> -->
					<div class="textStyle">
						<div class="textHead"><b></b><span>科室特色</span></div>
						<div class="textCon" v-html="info.memoScope"></div>
					</div>
					<div class="textStyle">
						<div class="textHead"><b></b><span>科研合作</span></div>
						<div class="textCon" v-html="info.memoPub"></div>
					</div>
				</div>
			</div>
		</div>
		<div ></div>
		<footercommon></footercommon>
	</div>
</template>

<script>
	import navheader from '../components/navheader.vue'
	import footercommon from '../components/footercommon.vue'
	import bannerswipt from "../components/bannerswipt.vue";
	import {GetDepart,GetDepartDetail,GetDoctor} from '@/api/api'
	export default{
		components:{navheader,footercommon,bannerswipt},
		data(){
			return{
				info:{},
				list:[],
				current:0,
				doctorList:[],
				hisdeptCode:'',
				
				conpages:'',//总页数
				cur:1, //当前页
				pageSize:5, //每页要展示数据条数
				showPage:[],
			}
		},
		mounted() {
			this.GetData(0)
			this.getDetail(this.list[0].guid)
			
		},
		methods:{
			setActiveItem(index){
				this.cur=index
				var list=this.cur*this.pageSize
				this.showPage=this.doctorList.slice(list,list+this.pageSize)
			},
			page(item){
				this.cur=item;  //当前页
				this.GetData(item)
			},
			getIndex(item){//导航切换地址
				this.current=item
				console.log(this.list[this.current].guid)
				this.getDetail(this.list[this.current].guid)
				this.getDoctor(this.list[this.current].guid);
				window.scrollTo(0,300);
			},
			GetData(val){
				GetDepart({status:1}).then(res => {
					this.list=res.data.data.rows;
					this.getDetail(res.data.data.rows[val].guid);
					this.getDoctor(res.data.data.rows[val].guid);
					this.current = val
					this.hisdeptCode=this.list[this.current].hisdeptCode
				})
			},
			getDetail(guid){//获取详细内容
				GetDepartDetail({guid: guid,status: 1}).then(res => {
				    this.info=res.data.data.rows[0]
				})
			},
			getDoctor(guid){
				GetDoctor({deptCode: guid,status:1}).then(res => {
					this.doctorList=res.data.data.rows
					this.showPage=[]
					res.data.data.rows.forEach((item,index)=>{
						if(index<5){
							this.showPage.push(item)
						}
					})
					this.conpages=Math.ceil(this.doctorList.length/this.pageSize)
				})
			},
			changeActive(row,index){
				this.current=index;
				this.getDetail(row.guid)
				this.getDoctor(row.guid);
				this.hisdeptCode=this.list[this.current].hisdeptCode
			},
			path(row){
				row={hisdeptCode:this.hisdeptCode,...row}
				this.$router.push({
					path:'/doctorDetail',
					query:row
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.departIntroduct{
		display: flex;
		align-items: center;
		.departContent{
			margin-left: 25px;
			.departTitle{
				font-size: 24px;
				font-weight: 400;
				margin-top: 10px;
			}
			.departDes{
				width: 350px;
				text-align: justify;
				margin-top: 10px;
				color:#999;
				font-size: 14px;
				line-height: 24px;
			}
		}
		
	}
	.doctorlist{
		margin: 30px 40px 50px;
		display: flex;
		width: 700px;
		.doctorItem{
			flex:1;
			.name{
				font-size: 19px;
			}
			.zhicheng{
				color:#999;
				font-size: 12px;
				margin-left: 4px;
			}
		}
		
	}
	.textStyle{
		margin-top: 40px;
		margin-bottom: 40px;
		margin-left: 40px;
		.textHead{
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			b{
				display: inline-block;
				width: 10px;
				height: 20px;
				background-color: #962437;
				margin-right: 10px;
			}
			span{
				font-size: 18px;
				font-weight: 400;
			}
		}
		.textCon{
			font-size: 14px;
			color: #999;
			width: 700px;
			text-align: justify;
			line-height: 24px;
		}
	}
	
	
</style>
