<template>
	<div class="wrapBox">
		<navheader current="7"></navheader>
		<div class="contentbox">
			<bannerswipt class="banner"></bannerswipt>
			<div class="leftbox">
				<div class="redbox">
					<div class="china">人才招聘</div>
					<!-- <div class="english">Job</div> -->
				</div>
				<div class="borderbox"></div>
			</div>
			<div class="rightbox">
				<div class="crumbs">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item :to="{ path: '/TalentRecruitment' }">人才招聘</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="talentlist">
					<div class="talentitem" v-for="(item,index) in talentList" :key="index" @click="path(item)">
						<div class="titlename">
							<div>{{item.title}}</div>
							<span>{{item.createTime}}</span>
						</div>
						<div class="gangwei" style="color:#999">
							<div>科室：{{item.title}}</div>
							<div>岗位：{{filtertype(item.dictCode)}}</div>
							<div>人数：{{item.imagePath1}}</div>
						</div>
						<!-- <div class="detail" v-html="item.memo"></div> -->
					</div>
				</div>
			</div>
		</div>
		<footercommon></footercommon>
	</div>
</template>

<script>
	import navheader from '../components/navheader.vue'
	import footercommon from '../components/footercommon.vue'
	import bannerswipt from "../components/bannerswipt.vue";
	import {GetTalentRecruitment,getDictionary} from '@/api/api'
	export default{
		components:{navheader,footercommon,bannerswipt},
		data(){
			return{
				talentList:[],
				dictCodeList:[],
			}
		},
		mounted() {
			this.GetData()
			this.getDictionary()
		},
		methods:{
			GetData(){
				GetTalentRecruitment({categoryId: 4,status:1}).then(res => {
				    this.talentList=res.data.data.rows
				})
			},
			path(item){
				this.$router.push({
					path:'/talentRecruitmentDetail',
					query:item
				})
			},
			//获取字典
			getDictionary() {
			  getDictionary({ bigCat: "zyDept",status: 1 }).then((res) => {
			    this.dictCodeList = res.data.data.rows;
			  });
			},
			//过滤类型
			filtertype(val){
			     let obj=this.dictCodeList.filter(el=>{
			           return el.code==val;
			     });
			     return obj.length ? obj[0].codeDesc :''
			},
		}
	}
</script>


<style lang="scss">
	.banner{
		width: 1000px;
		height: 340px;
	}
	.contentbox{
		width: 1000px;
		margin: 0 auto;
		margin-top: 30px;
		font-size: 14px;
		min-height: 400px;
		.leftbox{
			position: relative;
			.redbox{
				width: 160px;
				height: 160px;
				background-color: #A41034;
				color:#fff;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				font-weight: 500;
				position: absolute;
				top:24px;
				z-index:99;
				.china{
					font-size: 24px;
					margin-bottom: 6px;
				}
				.english{
					font-size: 16px;
				}
			}
			.borderbox{
				position: absolute;
				left:80px;
				border:3px solid #000;
				width: 178px;
				min-height: 210px;
				z-index:1;
			}
		}
		.rightbox{
			margin-left: 284px;
			.crumbs{
				border-bottom:2px solid #000000;
				 line-height: 40px;
				 cursor: pointer;
				 padding-bottom: 10px;
			}
		}
	}
	// 左边小导航
	.listBox{
		padding:230px 0 30px 47px;
		font-size: 18px;
		font-weight: 600;
		div{
			line-height: 40px;
			margin-bottom: 30px;
			cursor: pointer;
			&.active{
				color:#A41034;
				.line{
					width:30px ;
					height: 7px;
					background: #A41034;
				}
			}
		}
		
	}
	.talentlist{
		// margin: 30px 0;
		.talentitem{
			padding:30px 10px 20px 10px;
			padding-bottom: 30px;
			border-bottom:1px solid #E4E4E4;
			.titlename{
				display: flex;
				justify-content: space-between;
				align-items: center;
				div{
					font-size: 24px;
				}
				span{
					color:#999;
				}
			}
			.gangwei{
				margin-top: 10px;
				display: flex;
				div{
					width: 20%;
				}
			}
			.detail{
				margin-top: 10px;
				color:#999999;
			}
		}
	}
	
</style>
