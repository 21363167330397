<template>
	<div>
		<div class="headerNavber">
			<div class="left" @click="showNav=!showNav" >
				<img src="../../../assets/images/phone/menu.png" alt="" width="20px" height="20px">
				<span>菜单</span>
			</div>
			<img src="../../../assets/images/logo.png" alt="" width="30px" height="30px" @click="backhome">
			
		</div>
		<div class="navbox" v-if="showNav">
			<span v-for="(item,index) in navlist" :key="index" @click="path(item)">{{item.name}}</span>
		</div>
		<div class="jiansheing" v-if="show==true">
			<img src="../../../assets/images/phone/Construction.png" alt="" width="32px" height="32px">
			<div class="title">该页面正在建设中</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				navlist:[
					{name:'医院概况',url:'/hospital_phone'},
					{name:'新闻动态',url:'/list_phone??categoryId=6&id=2'},
					{name:'门诊导航',url:'/article_phone?guid=a6a9a559-5d84-4ee6-9524-2484525ccfc8&id=3'},
					{name:'学科介绍',url:'/experience_phone'},
					{name:'专家介绍',url:'/doctorlist_phone'},
					{name:'科研教育',url:'/list_phone?categoryId=12&id=1'},
					{name:'人才招聘',url:'/list_phone?categoryId=4&id=4'},
					// {name:'科普专栏',url:'/kepu_phone?categoryId=9&id=1'},
					{name:'科普专栏',url:'/list_phone??categoryId=9&id=6'},
					{name:'院务公开',url:'/list_phone?categoryId=3&id=1'},
					{name:'专题网站',url:''},
					
				],
				showNav:false,
				show:false,
				
			}
		},
		mounted() {
			
		},
		methods:{
			path(item){
				console.log(item.url)
				this.showNav=false
				if(item.url==''){
					this.show=true;
					setTimeout(()=>{this.show=false},3000)
				}else{
					this.$router.push(item.url)
					this.$emit('current',item.url)
				}
				
			},
			backhome(){
				this.$router.push('/home_phone')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.headerNavber{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 44px;
		padding: 0 20px;
		background-color: #fff;
		.left{
			display: flex;
			align-items: center;
			span{
				display: inline-block;
				margin-left:10px;
			}
		}
	}
	.navbox{
		position: fixed;
		z-index: 999;
		background-color: #ffffff;
		display: flex;
		flex-wrap: wrap;
		padding: 20px 0;
		span{
			color:#666666;
			display: flex;
			width: 20%;
			justify-content: center;
			line-height: 36px;
		}
	}
</style>
