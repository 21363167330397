<template>
	<div>
		<smallNavbar></smallNavbar>
		<swipt></swipt>
		<div class="iconBox">
			<div class="iconItem" v-for="(item,index) in iconList" :key="index" @click="path(item)">
				<img :src="item.icon" alt="" width="48px" height="48px">
				<span>{{item.name}}</span>
			</div>
		</div>
		<div class="newbox">
			<div class="title">新闻动态</div>
			<div class="newlist" v-for="(item,index) in newlist" :key="index">
				<img :src="$imgUrl+item.imagePath1" alt="" width="120px" height="82px">
				<span class="subtitle"> > {{item.title}}</span>
			</div>
		</div>
		<div class="jiansheing" v-if="show==true">
			<img src="../../assets/images/phone/Construction.png" alt="" width="32px" height="32px">
			<div class="title">该页面正在建设中</div>
		</div>
	</div>
</template>

<script>
	import smallNavbar from "./components/smallNavbar.vue";
	import swipt from "../../components/swipt.vue";
	import { GetTalentRecruitment } from "@/api/api";
	export default{
		components: { swipt,smallNavbar },
		data(){
			return {
				iconList:[
					{icon:require('../../assets/images/phone/Expert.png'),name:'专家介绍',url:'/doctorlist_phone'},
					{icon:require('../../assets/images/phone/Discipline.png'),name:'学科介绍',url:'/experience_phone'},
					{icon:require('../../assets/images/phone/clinic.png'),name:'门诊时间',url:'/article_phone?guid=24786901-c659-4fd0-a01d-4f5ff8f524a0&id=2'},
					// {icon:require('../../assets/images/phone/ONLINE.png'),name:'在线预约',url:''}
				],
				newlist:[],
				show:false,
			}
		},
		mounted() {
			this.getNew()
			if (this.isMobile()) {
			    // 执行移动端代码
				this.$router.push('/home_phone')
			  } else {
			    this.$router.push('/')
			}
		},
		methods:{
			isMobile() {
			    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
				console.log(width)
			    return width < 768; // 假设屏幕宽度小于768px为移动设备
			},
			getNew() {
			  //获取新闻咨询
			  GetTalentRecruitment({ categoryId: 6,status: 1 }).then((res) => {
				  res.data.data.rows.forEach((item, index) => {
				    if (index < 5) {
				      this.newlist.push(item);
				    }
				  });
			  });
			},
			path(item){
				if(item.url==''){
					this.show=true;
					setTimeout(()=>{this.show=false},3000)

				}else{
					this.$router.push(item.url)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.iconBox{
		display: flex;
		padding: 20px 10px;
		.iconItem{
			display: flex;
			flex-direction: column;
			flex:1;
			align-items: center;
			justify-content: center;
			span{
				display: inline-block;
				margin-top: 10px;
			}
		}
	}
	.newbox{
		padding: 0px 20px 0 20px;
		.title{
			font-weight: bold;
			margin: 10px 0 ;
		}
		.newlist{
			display: flex;
			align-items: flex-start;
			margin-bottom: 10px;
			.subtitle{
				margin-left: 10px;
				color:#666666;
			}
		}
	}
</style>

<style >
	.jiansheing{
		position: fixed;
		width: 260px;
		height: 60px;
		line-height: 60px;
		background-color: #ffffff;
		top:300px;
		left:50%;
		margin-left: -130px;
		z-index: 99;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		background-color: #fff2f2;
		.title{
			margin-left: 10px;
			color:#333;
		}
	}
</style>
