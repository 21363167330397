<template>
	<div class="wrapBox">
		<navheader :current='current'></navheader>
		<div class="contentbox">
			<bannerswipt class="banner"></bannerswipt>
			<div class="leftbox">
				<div class="redbox">
					<div class="china">{{$route.query.b}}</div>
				</div>
				<div class="borderbox">
					<div class="listBox" v-if="$route.query.a==8" >
						<div :class="{active:current==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
							<span>{{item}}</span>
							<div class="line"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="crumbs">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item v-if="$route.query.a==-1">{{$route.query.b}}</el-breadcrumb-item>
					  <el-breadcrumb-item v-if="$route.query.a==8" :to="{ path: '/anli' }">{{$route.query.b}}</el-breadcrumb-item>
					  <el-breadcrumb-item v-if="$route.query.a==8" :to="{ path: '/anli' }">{{$route.query.b}}</el-breadcrumb-item>
					  <el-breadcrumb-item v-if="$route.query.a==6" :to="{ path: '/education' }">{{$route.query.b}}</el-breadcrumb-item>
					  <el-breadcrumb-item v-if="$route.query.a==9" :to="{ path: '/Publicity' }">{{$route.query.b}}</el-breadcrumb-item>
					  <el-breadcrumb-item >详情</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="maincontent">
					<div class="title">{{info.title}}</div>
					<div class="time">发布时间：{{info.createTime}} </div>
					<img :src="$imgUrl+info.imagePath1" alt="" style="width: 56.2%;height: 300px;" v-if="info.imagePath1">
					<div v-html="info.memo" class="des"></div>
				</div>
			</div>
		</div>
		<footercommon></footercommon>
	</div>
</template>

<script>
	import navheader from '../components/navheader.vue'
	import footercommon from '../components/footercommon.vue'
	import bannerswipt from "../components/bannerswipt.vue";
	import {GetTalentRecruitment} from '@/api/api'
	export default{
		components:{navheader,footercommon,bannerswipt},
		data(){
			return{
				current:0,
				list:['科普专栏','视频中心'],
				info:{},
			}
		},
		mounted() {
			this.GetData();
		},
		methods:{
			GetData(){
				GetTalentRecruitment({guid:this.$route.query.guid}).then(res => {
					this.info=res.data.data.rows[0]
					console.log(this.info)
				})
			},
			changeActive(row,index){
				this.current=index;
				console.log(index)
				if(index==0){
					this.$router.push('/anli')
				}else{
					this.$router.push('/experience')
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.maincontent{
		padding:30px 10px;
		.title{
			font-size: 16px;
			font-weight: bold;
			text-align: center;
		}
		.time{
			font-size: 14px;
			color:#666666;
			text-align: center;
			margin: 15px 0 30px;
			span{
				margin-left: 20px;
			}
		}
		.des{
			color: #666;
		}
		
	}

</style>
