<template>
	<div class="wrapBox">
		<div class="phone_banner">
			<smallNavbar @current="current()"></smallNavbar>
		</div>
		<div class="phoneContent">
			<div class="tabBoxPhone">
				<div class="itembox" :class="{active:current==index}"  v-for="(item,index) in list" :key="index" @click="changeActive(item,index)">
					<span>{{item.hisdeptName}}</span>
					<div class="line"></div>
				</div>
			</div>
			<div class="departcontent">
				<img :src="$imgUrl+info.photoImg" alt="" class="departIntroductImg" style="width: 100%;height: 193px;" v-if="info.photoImg">
				<div class="departContent">
					<div class="departTitle">{{info.deptName}}科室简介</div>
					<div class="departDes"  v-html="info.deptIntroduction"></div>
				</div>
				<div class="textStyle">
					<div class="textHead"><b></b><span>科室特色</span></div>
					<div class="textCon" v-html="info.memoScope"></div>
				</div>
				<div class="textStyle">
					<div class="textHead"><b></b><span>科研成果</span></div>
					<div class="textCon" v-html="info.memoPub"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import smallNavbar from "./components/smallNavbar.vue";
	import {GetDepart,GetDepartDetail,GetDoctor} from '@/api/api'
	export default{
		components:{smallNavbar},
		data(){
			return{
				info:{},
				list:[],
				current:0,
				hisdeptCode:'',
				
				conpages:'',//总页数
				cur:1, //当前页
				pageSize:5, //每页要展示数据条数
				showPage:[],
			}
		},
		mounted() {
			this.GetData(0)
			
		},
		methods:{
			page(item){
				this.cur=item;  //当前页
				this.GetData(item)
			},
			GetData(val){
				GetDepart({status:1}).then(res => {
					this.list=res.data.data.rows;
					this.getDetail(res.data.data.rows[val].guid);
					this.current = val
					this.hisdeptCode=this.list[this.current].hisdeptCode
				})
			},
			getDetail(guid){//获取详细内容
				GetDepartDetail({guid: guid,status: 1}).then(res => {
				    this.info=res.data.data.rows[0]
				})
			},
			changeActive(row,index){
				this.current=index;
				this.getDetail(row.guid)
				this.hisdeptCode=this.list[this.current].hisdeptCode
			},
		}
	}
</script>

<style lang="scss" scoped>
	
	.departIntroduct{
		display: flex;
		align-items: center;
		.departContent{
			margin-left: 25px;
			.departTitle{
				font-size: 24px;
				font-weight: 400;
				margin-top: 10px;
			}
			.departDes{
				width: 350px;
				text-align: justify;
				margin-top: 10px;
				color:#999;
				font-size: 14px;
				line-height: 24px;
			}
		}
		
	}
	.textStyle{
		margin-top: 40px;
		margin-bottom: 40px;
		.textHead{
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			b{
				display: inline-block;
				width: 10px;
				height: 20px;
				background-color: #962437;
				margin-right: 10px;
			}
			span{
				font-size: 18px;
				font-weight: 400;
			}
		}
		.textCon{
			font-size: 14px;
			color: #999;
			text-align: justify;
			line-height: 24px;
		}
	}
	
	
</style>
<style lang="scss">
	.tabBoxPhone{
		display: flex;
		margin-bottom:20px;
		.itembox{
			flex:1;
			border:1px solid #ddd;
			text-align: center;
			padding:10px 0;
			&.active{
				background-color: #962437;
				color:#fff;
			}
		}
	}
</style>

